import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';

import { API_BASE_URL, BASE_URL } from '../config';

import Header from '../components/Header';
import Sitemap from '../components/Sitemap';
import LoginForm from '../components/LoginForm';
import Footer from '../components/Footer';
import SubscribeNewsletter from '../components/SubscribeNewsletter';
import { logout } from '../reducers/user';
import { notifySuccess } from "../utils/toastify";
import { callViewContentAPI } from '../utils/conversionApiEvents';

const UserProfile = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const user = useSelector((state) => state.user.value);

    useEffect(() => {
        AOS.init({
            duration : 1000
        });
    }, []);

    const [isLoggedIn, setIsLoggedIn] = useState(user.is_logged_in === false ? true : false);

    const [orders, setOrders] = useState([]);
    const [showOrdersList, setShowOrdersList] = useState(false);

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const getLoginStatus = (e) => {
        if(e.is_logged_in === true){
            setIsLoggedIn(true);
            // if(user.locations.length === 0){
            //     setIsShowCheckoutDetails(false);
            //     setIsShowAddNewLocation(true);
            // }
            // else{
            //     setIsShowAddNewLocation(false);
            //     getAndSetCartProducts(cart);
            //     setIsShowCheckoutDetails(true);
            // }
        }
    }

    const logoutUser = () => {
        dispatch(logout());
    }

    useEffect(() => {
        if(user.is_logged_in === false){
            setIsLoggedIn(false);
        }
        else{
            setIsLoggedIn(true);
            getUserOrdersByUserId(user.id);
        }
    }, [user]);

    const getUserDetailsByUserId = async (user_id) => {

        try{
            const getUserReq = await axios.get(`${API_BASE_URL}customer/${user_id}`, config);
            if(getUserReq.status === 200){
                return getUserReq.data
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    const getUserDetailsHandler = async () => {
        const userDetails = await getUserDetailsByUserId(user.id);
    }

    useEffect(() => {
        // getUserDetailsHandler(); 
    }, []);

    const getUserOrdersByUserId = async (user_id) => {

        const config = {
            headers: {
                'Authorization': user.access_token
            }
        }

        try{
            const getUserOrdersReq = await axios.get(`${API_BASE_URL}customer/orders/delivered/${user_id}`, config);
            if(getUserOrdersReq.status === 200){
                setOrders(getUserOrdersReq.data.items);
                if(getUserOrdersReq.data.items.length != 0){
                    setShowOrdersList(true);
                }
                else{
                    setShowOrdersList(false);
                }
            }
            else{
                setShowOrdersList(false);
            }
        }
        catch(error) {
            console.log(error);
            setShowOrdersList(false);
        }
    }

    const rateThisOrder = async (order_id, rating) => {

        const data = {
            rating: rating
        }

        const rateThisOrderReq = await axios.patch(`${API_BASE_URL}order/rate/${order_id}`, data, config);
        if(rateThisOrderReq.status === 200){
            notifySuccess("Thank You For Rating The Order!");
            getUserOrdersByUserId(user.id);
        }
    }

    return (
        <>
            <Header />
            <Sitemap pageName={"profile"} pagePath={"profile"} />

            {
                isLoggedIn === false
                ?
                    <div className="checkoutPage">
                        <div className="checkoutPageLoginForm">
                            <LoginForm getLoginStatus={(e) => {getLoginStatus(e)}} />
                        </div>
                    </div>
                :
                <>
                    <div className="myAccount">
                        <div className="myAccountInner">
                            <div className="myAccountNav">
                                <div className="myAccountNavInner">
                                    <div className={location.pathname == "/profile" ? "myAccountNavSingle active" : "myAccountNavSingle"}>
                                        <Link to="/profile">
                                            <p className="myAccountNavSingleTxt">my account</p>
                                        </Link>
                                    </div>
                                    <div className="myAccountNavSingle" onClick={logoutUser}>
                                        <p className="myAccountNavSingleTxt">sign out</p>
                                    </div>
                                </div>
                            </div>

                            <div className="myAccountRightSection">
                                <div className="myAccountDetails">
                                    <div className="myAccountDetailsInner">
                                        <div className="myAccountDetailStep">
                                            <div className="myAccountDetailCover">
                                                <div className="myAccountDetailCoverImage">
                                                    <img src={`${BASE_URL}images/user-default.jpg`} className="myAccountDetailCoverImg" />
                                                </div>
                                            </div>

                                            <div className="myAccountDetailContent">
                                                <div className="myAccountDetailContentName">
                                                    <p className="myAccountDetailContentNameTxt">{user.name}</p>
                                                </div>
                                                <div className="myAccountDetailContentPhone">
                                                    <p className="myAccountDetailContentPhoneTxt">Phone Number: {user.phone_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    showOrdersList === true
                                    ?
                                    <div className="myOrdersContainer">
                                        <div className="myOrdersHeading">
                                            <p className="myOrdersHeadingTxt">my orders</p>
                                        </div>
                                        <div className="myOrdersList">
                                            {
                                                orders.map(order => {
                                                    let localDate = new Date(order.ordered_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
                                                    let displayDate = moment(localDate).format('Do MMMM YYYY, h:mm a');
                                                    return(
                                                        <div className="myOrderSingle" key={order.id}>
                                                            <div className="myOrderSingleDetails">
                                                                <div className="myOrderSingleIdStatus">
                                                                    <div className="myOrderSingleId">
                                                                        <p className="myOrderSingleIdTxt">Order ID: {order.order_id}</p>
                                                                    </div>
                                                                    <div className="myOrderSingleStatus">
                                                                        {
                                                                            order.is_online === false
                                                                            ?
                                                                                order.delivery_type === "DINE_IN"
                                                                                ?
                                                                                <p className="myOrderSingleStatusTxt">DineIn At: Nagarbhavi</p>
                                                                                :
                                                                                order.delivery_type === "TAKEAWAY"
                                                                                ?
                                                                                <p className="myOrderSingleStatusTxt">Takeaway At: Nagarbhavi</p>
                                                                                :
                                                                                order.delivery_type === "DELIVERY"
                                                                                ?
                                                                                <p className="myOrderSingleStatusTxt">Delivered To: {order.delivery?.name}</p>
                                                                                :
                                                                                <></>
                                                                            :
                                                                            order.delivery_type === "DINE_IN"
                                                                            ?
                                                                            <p className="myOrderSingleStatusTxt">DineIn At: Nagarbhavi</p>
                                                                            :
                                                                            order.delivery_type === "TAKEAWAY"
                                                                            ?
                                                                            <p className="myOrderSingleStatusTxt">Takeaway To: {order.delivery?.name}</p>
                                                                            :
                                                                            order.delivery_type === "DELIVERY"
                                                                            ?
                                                                            <p className="myOrderSingleStatusTxt">Delivered To: {order.delivery?.name}</p>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>

                                                                    <div className="myOrderSingleStatusMain">
                                                                        {
                                                                            order.status === "DELIVERED"
                                                                            ?
                                                                                <p className="myOrderSingleStatusMainTxt delivered">delivered</p>
                                                                            :
                                                                            order.status === "SENT"
                                                                            ?
                                                                                <p className="myOrderSingleStatusMainTxt accepted">out for delivery</p>
                                                                            :
                                                                            order.status === "ACCEPTED"
                                                                            ?
                                                                                <p className="myOrderSingleStatusMainTxt accepted">accepted</p>
                                                                            :
                                                                            order.status === "RECEIVED" || order.status === "ORDERED"
                                                                            ?
                                                                                <p className="myOrderSingleStatusMainTxt ordered">ordered</p>
                                                                            :
                                                                            order.status === "CANCELLED"
                                                                            ?
                                                                                <p className="myOrderSingleStatusMainTxt cancelled">cancelled</p>
                                                                            :
                                                                            order.status === "REJECTED"
                                                                            ?
                                                                                <p className="myOrderSingleStatusMainTxt cancelled">rejected</p>
                                                                            :
                                                                                <></>
                                                                        }
                                                                        
                                                                    </div>
                                                                </div>

                                                                <div className="myOrderSingleItemsTime">
                                                                    <div className="myOrderSingleItems">
                                                                        <div className="myOrderSingleItemsLable">
                                                                            <p className="myOrderSingleItemsLableTxt">Items:</p>
                                                                        </div>
                                                                        <div className="myOrderSingleItemsList">
                                                                            <p className="myOrderSingleItemsListTxt">
                                                                                {
                                                                                    order.products.map(product => {
                                                                                        return(
                                                                                            <span className="myOrderSingleItemsListSingle" key={product.id}>
                                                                                                <span className="myOrderSingleItemsListContent">{product.quantity} X {product.product.name}</span>
                                                                                                <span className="myOrderSingleItemsListComa">,&nbsp;</span>
                                                                                                <span className="myOrderSingleItemsListStop">.&nbsp;</span>
                                                                                            </span>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                    <div className="myOrderSingleTime">
                                                                        <div className="myOrderSingleTimeLable">
                                                                            <p className="myOrderSingleTimeLableTxt">Ordered On:</p>
                                                                        </div>
                                                                        <div className="myOrderSingleTimeContent">
                                                                            <p className="myOrderSingleTimeContentTxt">{displayDate}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            

                                                            <div className="myOrderSingleRatingPrice">
                                                                {
                                                                    order.rating === 0
                                                                    ?
                                                                    <div className="myOrderSingleRating">
                                                                        <div className="myOrderSingleRatingLable">
                                                                            <p className="myOrderSingleRatingLableTxt">rate order:</p>
                                                                        </div>
                                                                        <div className="myOrderSingleRatingStars">
                                                                            <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 1)}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">1</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 2)}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">2</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 3)}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">3</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 4)}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">4</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 5)}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">5</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="myOrderSingleRating">
                                                                        <div className="myOrderSingleRatingLable">
                                                                            <p className="myOrderSingleRatingLableTxt">You Rated:</p>
                                                                        </div>
                                                                        <div className="myOrderSingleRatingStars">
                                                                            <div className={order.rating >= 1 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">1</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className={order.rating >= 2 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">2</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className={order.rating >= 3 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">3</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className={order.rating >= 4 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">4</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className={order.rating >= 5 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                                                <div className="myOrderSingleRatingStarLable">
                                                                                    <p className="myOrderSingleRatingStarLableTxt">5</p>
                                                                                </div>
                                                                                <div className="myOrderSingleRatingStarIcon">
                                                                                    <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                
                                                                <div className="myOrderSinglePrice">
                                                                    <div className="myOrderSinglePriceInner">
                                                                        <div className="myOrderSinglePriceIcon">
                                                                            <i className="fas fa-rupee-sign myOrderSinglePriceIco"></i>
                                                                        </div>
                                                                        <div className="myOrderSinglePriceLable">
                                                                            <p className="myOrderSinglePriceLableTxt">{order.costing.final_price}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }

            <SubscribeNewsletter />
            <Footer />
        </>
    )
}

export default UserProfile
import { createSlice } from "@reduxjs/toolkit";

const initialValue = {products: [], is_coupon_applied: false, coupon: '', coupon_id: '', coupon_savings: 0}

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        value: initialValue
    },
    reducers: {
        addProduct: (state, action) => {
            state.value.products.push(action.payload);
        },
        increaseProductQty: (state, action) => {
            console.log(action.payload);

            let products = [...state.value.products];
            let prodIndex = products.findIndex(prod => prod.id === action.payload);

            products[prodIndex].quantity += 1;
            state.value.products = products;
        },
        decreaseProductQty: (state, action) => {
            let products = [...state.value.products];
            let prodIndex = products.findIndex(prod => prod.id === action.payload);
            products[prodIndex].quantity -= 1;
            state.value.products = products;
        },
        removeCartProduct: (state, action) => {
            let products = [...state.value.products];
            let prodIndex = products.findIndex(prod => prod.id === action.payload);
            products.splice(prodIndex, 1);
            state.value.products = products;
        },
        clear: (state) => {
            state.value = initialValue;
        },
        removeCoupon: (state) => {
            state.value.is_coupon_applied = false;
            state.value.coupon = '';
            state.value.coupon_id = '';
            state.value.coupon_savings = 0;
        },
        updateCoupon: (state, action) => {
            state.value.is_coupon_applied = action.payload.is_coupon_applied;
            state.value.coupon = action.payload.coupon_code;
            state.value.coupon_id = action.payload.coupon_id;
            state.value.coupon_savings = action.payload.coupon_savings;
        }
    }
});

export const { addProduct, increaseProductQty, decreaseProductQty, removeCartProduct, clear, removeCoupon, updateCoupon } = cartSlice.actions;

export default cartSlice.reducer;
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import axios from 'axios';
import { notifyError, notifySuccess } from '../utils/toastify';
import { API_BASE_URL } from '../config';
import Header from '../components/Header';
import Sitemap from '../components/Sitemap';
import Footer from '../components/Footer';
import { callContactAPI, callViewContentAPI } from '../utils/conversionApiEvents';
import { useSelector } from 'react-redux';

const ContactPage = () => {

    const user = useSelector((state) => state.user.value);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    
    const [isNameValid, setIsNameValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isMobileValid, setIsMobileValid] = useState(true);
    const [isSubjectValid, setIsSubjectValid] = useState(true);
    const [isMessageValid, setIsMessageValid] = useState(true);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

    const validateEmail = function (email) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(email) == false) {
            return false;
        }
        return true;
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
        setIsNameValid( !!e.target.value);
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value.trim());
        setIsEmailValid(!!e.target.value.trim() && validateEmail(e.target.value));
    }
    const handleMobileChange = (e) => {
        setMobile(e.target.value.trim());
        setIsMobileValid(!isNaN(e.target.value) && e.target.value !== "");
    }
    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
        setIsSubjectValid(!!e.target.value);
    }
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        setIsMessageValid(!!e.target.value);
    }

    const isCheckboxCheckedHandler = (e) => {
        if(e.target.checked === false){
            setIsCheckboxChecked(false);
        }
        else if(e.target.checked === true){
            setIsCheckboxChecked(true);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if(isCheckboxChecked === true){
            setIsEmailValid(!!email);
            setIsNameValid(!!name);
            setIsMobileValid(!!mobile);
            setIsSubjectValid(!!subject);
            setIsMessageValid(!!message);
    
            if (!!name && !!email && mobile && !!subject && !!message && isNameValid && isEmailValid && isMobileValid && isSubjectValid && isMessageValid) {
                const data = { 
                    name: name, 
                    email_id: email, 
                    phone: mobile, 
                    subject: subject, 
                    message: message 
                }
                
                try {
                    const saveContactFormData = await axios.post(`${API_BASE_URL}contact-form`, data);
                    if(saveContactFormData.status === 201){
                        callContactAPI();
                        setName("");
                        setEmail("");
                        setMobile("");
                        setSubject("");
                        setMessage("");
                        notifySuccess("Request successfully submitted.");
                    }
                    else{
                        notifyError("Something went wrong. Please try again.");
                    }
                }
                catch (error) {
                    notifyError("Something went wrong. Please try again.");
                }
            }
        }
        else {
            notifyError("Please accept the terms & conditions.");
        }
    }

    useEffect(() => {
        AOS.init({
            duration : 1000
        });
    }, []);

    return (
        <>
            <Header />
            <Sitemap pageName="contact us" pagePath="contact-us" />

            <div className="contactUsDetails">
                <div className="contactUsDetailsInner">
                    <div className="contactUsDetailsForm">
                        <div className="contactUsDetailsFormHeadings">
                            <div className="contactUsDetailsFormHeading">
                                <p className="contactUsDetailsFormHeadingTxt">leave a message</p>
                            </div>
                            <div className="contactUsDetailsFormDescription">
                                <p className="contactUsDetailsFormDescriptionTxt">We do not spam your email with junk emails.</p>
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="contactUsDetailsFormMain">
                            <div className="contactUsDetailsFormStep">
                                <div className="contactUsDetailsFormStepHalf">
                                    <div className="contactUsDetailsFormStepInput">
                                        <input type="text" className="contactUsDetailsFormStepHalfInputTxt" placeholder="name *" value={name} onChange={(e) => handleNameChange(e)} />
                                        {
                                                isNameValid
                                                    ? null : <p className="customizePopUpFormErrorTxt">Invalid name</p>
                                                    
                                        }
                                    </div>
                                </div>
                                <div className="contactUsDetailsFormStepHalf">
                                    <div className="contactUsDetailsFormStepInput">
                                        <input type="text" className="contactUsDetailsFormStepHalfInputTxt email" placeholder="Email *" value={email} onChange={(e) => handleEmailChange(e)} />
                                        {
                                                isEmailValid
                                                    ? null : <p className="customizePopUpFormErrorTxt">Invalid email</p>
                                                    
                                        }        
                                    </div>
                                </div>
                            </div>
                            <div className="contactUsDetailsFormStep">
                                <div className="contactUsDetailsFormStepHalf">
                                    <div className="contactUsDetailsFormStepInput">
                                        <input type="text" className="contactUsDetailsFormStepHalfInputTxt" placeholder="phone *" value={mobile} onChange={(e) => handleMobileChange(e)}/>
                                        {
                                                isMobileValid
                                                    ? null : <p className="customizePopUpFormErrorTxt">Invalid phone</p>
                                                    
                                        }
                                    </div>
                                </div>
                                <div className="contactUsDetailsFormStepHalf">
                                    <div className="contactUsDetailsFormStepInput">
                                        <input type="text" className="contactUsDetailsFormStepHalfInputTxt" placeholder="subject *" value={subject}  onChange={(e) => handleSubjectChange(e)}/>
                                        {
                                                isSubjectValid
                                                    ? null : <p className="customizePopUpFormErrorTxt">Invalid subject</p>
                                                    
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="contactUsDetailsFormStep">
                                <div className="contactUsDetailsFormStepInput">
                                    <textarea className="contactUsDetailsFormStepHalfInputTxtarea" placeholder="your message *" rows="3" value={message} onChange={(e) => handleMessageChange(e)}></textarea>
                                    {
                                                isMessageValid
                                                    ? null : <p className="customizePopUpFormErrorTxt">Invalid message</p>
                                                    
                                        }
                                </div>
                            </div>
                            <div className="contactUsDetailsFormStep">
                                <div className="contactUsDetailsFormStepCheck">
                                    <label className="checkbox">
                                        <input type="checkbox" defaultChecked={isCheckboxChecked} onChange={(e) => isCheckboxCheckedHandler(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="contactUsDetailsFormStepCheckText">
                                    <p className="contactUsDetailsFormStepCheckTxt">accept terms &amp; conditions and privacy policy.</p>
                                </div>
                            </div>
                                    
                            <div className="contactUsDetailsFormStep">
                                <div className="contactUsDetailsFormStepInput">
                                    <input type="submit" value="send a message" className="contactUsDetailsFormStepInputButton" />
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>

                    <div className="contactUsDetailsAddress">
                        <div className="contactUsDetailsAddressInner">
                            <div className="contactUsDetailsAddressHeading">
                                <p className="contactUsDetailsAddressHeadingTxt">our store</p>
                            </div>
                            <div className="contactUsDetailsAddressContent">
                                <div className="contactUsDetailsAddressContentStep">
                                    <div className="contactUsDetailsAddressContentStepLable">
                                        <p className="contactUsDetailsAddressContentStepLableTxt">address</p>
                                    </div>
                                    <div className="contactUsDetailsAddressContentStepValue">
                                        <p className="contactUsDetailsAddressContentStepValueTxt">563, 60f road, 6th Main Rd, Srigandha kaval, D-Group Layout, Naagarabhaavi, Bengaluru, Karnataka 560091</p>
                                    </div>
                                </div>
                                <div className="contactUsDetailsAddressContentStep">
                                    <div className="contactUsDetailsAddressContentStepLable">
                                        <p className="contactUsDetailsAddressContentStepLableTxt">email ID</p>
                                    </div>
                                    <div className="contactUsDetailsAddressContentStepValue">
                                        <p className="contactUsDetailsAddressContentStepValueTxt">info@blackbox.pizza</p>
                                    </div>
                                </div>
                                <div className="contactUsDetailsAddressContentStep">
                                    <div className="contactUsDetailsAddressContentStepLable">
                                        <p className="contactUsDetailsAddressContentStepLableTxt">phone</p>
                                    </div>
                                    <div className="contactUsDetailsAddressContentStepValue">
                                        <p className="contactUsDetailsAddressContentStepValueTxt">+91 99865 80893 / +91 80 2390 1652</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contactUsMap">
                <div className="contactUsMapFrame">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9630976166386!2d77.49523081534326!3d12.974212118330115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3dfaddd903b3%3A0x62cb93ff8dc61f74!2sBLACKBOX%20PIZZA!5e0!3m2!1sen!2sin!4v1638732567421!5m2!1sen!2sin"  className="contactUsMapFrameMain" loading="lazy"></iframe>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ContactPage
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { BASE_URL, GOOGLE_MAPS_API_KEY } from '../config';
import { ToastContainer } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../reducers/user';

function useWindowSize() {

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []); 
    return windowSize;
}

function Header() {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.value);
    const cart = useSelector((state) => state.cart.value);

    const location = useLocation();
    
    const [scrollPosition, setScrollPosition] = useState(0);

    const size = useWindowSize();

    const [isMobile, setIsMobile] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let screen = size.width;
        if(screen <= 650){
            setIsMobile(true);
        }
        else{
            setIsMobile(false);
        }
    }, [size]);
    
    return (
        <>
            <div className="headerSubHeader">
                <div className="subHeader">
                    <div className="subHeaderInner">
                        <div className="subHeaderFollow">
                            <a className="subHeaderFollowSingle" href="https://www.facebook.com/blackboxpizza" rel="noreferrer" target="_blank">
                                <i className="fab fa-facebook-f subHeaderFollowSingleIco"></i>
                            </a>
                            <a className="subHeaderFollowSingle" href="https://www.instagram.com/blackbox.pizza/" rel="noreferrer" target="_blank">
                                <i className="fab fa-instagram subHeaderFollowSingleIco"></i>
                            </a>
                            <a className="subHeaderFollowSingle" href="https://wa.me/919986580893" rel="noreferrer" target="_blank">
                                <i className="fab fa-whatsapp subHeaderFollowSingleIco"></i>
                            </a>
                            <a className="subHeaderFollowSingle" href="https://goo.gl/maps/HJNwSKa3u6BUFzT47" rel="noreferrer" target="_blank">
                                <i className="fas fa-map-marker-alt subHeaderFollowSingleIco"></i>
                            </a>
                        </div>

                        <div className="subHeaderContactApp">
                            <div className="subHeaderContact">
                                <div className="subHeaderContactSingle">
                                    <div className="subHeaderContactSingleIcon">
                                        <i className="fas fa-map-signs subHeaderContactSingleIco"></i>
                                    </div>
                                    <div className="subHeaderContactSingleLable">
                                        <p className="subHeaderContactSingleLableTxt">nagarbhavi, bangalore.</p>
                                    </div>
                                </div>
                                <div className="subHeaderContactSingle">
                                    <div className="subHeaderContactSingleIcon">
                                        <i className="fas fa-phone-alt subHeaderContactSingleIco"></i>
                                    </div>
                                    <div className="subHeaderContactSingleLable">
                                        <p className="subHeaderContactSingleLableTxt">+91 80 2390 1652</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header">
                    <div className="headerInner">
                        {
                            scrollPosition <= 100
                            ?
                            <div className="headerLogo" id="headerLogoWithBg">
                                <div className="headerLogoImage">
                                    <img src={`${BASE_URL}images/logo-header.png`} className="headerLogoImg" />
                                </div>
                            </div>
                            :
                            <div className="headerLogoNoBg" id="headerLogoNoBg">
                                <div className="headerLogoNoBgImage">
                                    <img src="/images/logo-blackbox-no-bg.png" className="headerLogoNoBgImg" />
                                </div>
                            </div>
                        }

                        {
                            isMobile === true
                            ?
                            <div className="headerNavMobileAction">
                                <div className="headerNavMobileActionInner">

                                    {
                                        cart?.products?.length != 0
                                        ?
                                            <Link to="/cart">
                                                <div className="headerNavMobileActionCart">
                                                    <div className="headerNavMobileActionCartBubble">
                                                        <p className="headerNavMobileActionCartBubbleTxt">{cart?.products?.length}</p>
                                                    </div>
                                                    <div className="headerNavMobileActionCartInner">
                                                        <i className="icofont-shopping-cart headerNavMobileActionCartIco"></i>
                                                    </div>
                                                </div>
                                            </Link>
                                            
                                        :
                                            <></>
                                    }
                                    {
                                        showMobileNav === false
                                        ?
                                        <div className="headerNavMobileActionClick" onClick={() => setShowMobileNav(true)}>
                                            <div className="headerNavMobileActionClickInner">
                                                <i className="fas fa-bars headerNavMobileActionClickIco"></i>
                                            </div>
                                        </div>
                                        :
                                        <div className="headerNavMobileActionHide" onClick={() => setShowMobileNav(false)}>
                                            <div className="headerNavMobileActionClickInner">
                                                <i className="fas fa-times headerNavMobileActionHideIco"></i>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                            :
                            <></>
                        }

                        {
                            showMobileNav === true
                            ?
                            <div className="headerNavMobileNav">
                                <div className="headerNavMobileNavArrow">
                                    <div className="headerNavMobileNavArrowIcon">
                                        <i className="fas fa-sort-up headerNavMobileNavArrowIco"></i>
                                    </div>
                                </div>
                                <div className="headerNavMobileNavInner"> 
                                    <div className={location.pathname == "/" ? "headerNavMobileNavSingle active" : "headerNavMobileNavSingle"}>
                                        <Link to="/">
                                            <div className="headerNavMobileNavSingleText">
                                                <p className="headerNavMobileNavSingleTxt">
                                                    home
                                                    <span className="headerNavMobileNavSingleTxtBorder">&nbsp;</span>
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={location.pathname == "/menu" ? "headerNavMobileNavSingle active" : "headerNavMobileNavSingle"}>
                                        <Link to="/menu">
                                            <div className="headerNavMobileNavSingleText">
                                                <p className="headerNavMobileNavSingleTxt">
                                                    menu
                                                    <span className="headerNavMobileNavSingleTxtBorder">&nbsp;</span>
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={location.pathname == "/cart" ? "headerNavMobileNavSingle active" : "headerNavMobileNavSingle"}>
                                        <Link to="/cart">
                                            <div className="headerNavMobileNavSingleText">
                                                <p className="headerNavMobileNavSingleTxt">
                                                    cart
                                                    <span className="headerNavMobileNavSingleTxtBorder">&nbsp;</span>
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={location.pathname == "/profile" ? "headerNavMobileNavSingle active" : "headerNavMobileNavSingle"}>
                                        <Link to="/profile">
                                            <div className="headerNavMobileNavSingleText">
                                                <p className="headerNavMobileNavSingleTxt">
                                                    my account
                                                    <span className="headerNavMobileNavSingleTxtBorder">&nbsp;</span>
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={location.pathname == "/about-us" ? "headerNavMobileNavSingle active" : "headerNavMobileNavSingle"}>
                                        <Link to="/about-us">
                                            <div className="headerNavMobileNavSingleText">
                                                <p className="headerNavMobileNavSingleTxt">
                                                    about us
                                                    <span className="headerNavMobileNavSingleTxtBorder">&nbsp;</span>
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={location.pathname == "/contact-us" ? "headerNavMobileNavSingle active" : "headerNavMobileNavSingle"}>
                                        <Link to="/contact-us">
                                            <div className="headerNavMobileNavSingleText">
                                                <p className="headerNavMobileNavSingleTxt">
                                                    contact us
                                                    <span className="headerNavMobileNavSingleTxtBorder">&nbsp;</span>
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                        }

                        <div className="headerNavActions">
                            <div className="headerNavActionsInner">
                                <div className="headerNav">
                                    <div className="headerNavSingle">
                                        <Link to="/">
                                            <p className={location.pathname == "/" ? "headerNavSingleTxt active" : "headerNavSingleTxt"}>home</p>
                                        </Link>
                                    </div>
                                    <div className="headerNavSingle">
                                        <Link to="/menu">
                                            <p className={location.pathname == "/menu" ? "headerNavSingleTxt active" : "headerNavSingleTxt"}>menu</p>
                                        </Link>
                                    </div>
                                    <div className="headerNavSingle">
                                        <Link to="/about-us">
                                            <p className={location.pathname == "/about-us" ? "headerNavSingleTxt active" : "headerNavSingleTxt"}>about us</p>
                                        </Link>
                                    </div>
                                    <div className="headerNavSingle">
                                        <Link to="/contact-us">
                                            <p className={location.pathname == "/contact-us" ? "headerNavSingleTxt active" : "headerNavSingleTxt"}>contact us</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="headerActionsButton">
                                    <div className="headerActions">
                                        <Link to="/profile">
                                            <div className="headerActionSingle">
                                                <div className="headerActionSingleIcon" >
                                                    <i className="far fa-user headerActionSingleIco"></i>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link to="/cart">
                                            <div className="headerActionSingle">
                                                <div className="headerActionSingleIcon">
                                                    <i className="fas fa-shopping-cart headerActionSingleIco"></i>
                                                    {
                                                        cart?.products?.length != 0
                                                        ?
                                                        <div className="headerActionSingleIconBubble">
                                                            <p className="headerActionSingleIconBubbleTxt">{cart?.products?.length}</p>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="headerButton">
                                        <Link to="/cart">
                                            <p className="headerButtonTxt">order now</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Header
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BASE_URL } from '../config';

const Footer = () => {
    const location = useLocation();

    return (
        <div className="footer">
            <div className="footerInner">
                <div className="footerDetails">
                    <div className="footerLinksSection" data-aos="fade-right">
                        <div className="footerLinksSectionHeading">
                            <p className="footerLinksSectionHeadingTxt">useful links</p>
                        </div>

                        <div className="footerLinks">
                            <Link to="/">
                                <div className={location.pathname == "/" ? "footerLinkSingle active" : "footerLinkSingle"}>
                                    <div className="footerLinkSingleIcon">
                                        <i className="fas fa-arrow-right footerLinkSingleIco"></i>
                                    </div>
                                    <div className="footerLinkSingleLable">
                                        <p className="footerLinkSingleLableTxt">home</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/about-us">
                                <div className={location.pathname == "/about-us" ? "footerLinkSingle active" : "footerLinkSingle"}>
                                    <div className="footerLinkSingleIcon">
                                        <i className="fas fa-arrow-right footerLinkSingleIco"></i>
                                    </div>
                                    <div className="footerLinkSingleLable">
                                        <p className="footerLinkSingleLableTxt">about us</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/menu">
                                <div className={location.pathname == "/menu" ? "footerLinkSingle active" : "footerLinkSingle"}>
                                    <div className="footerLinkSingleIcon">
                                        <i className="fas fa-arrow-right footerLinkSingleIco"></i>
                                    </div>
                                    <div className="footerLinkSingleLable">
                                        <p className="footerLinkSingleLableTxt">menu</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/services">
                                <div className={location.pathname == "/services" ? "footerLinkSingle active" : "footerLinkSingle"}>
                                    <div className="footerLinkSingleIcon">
                                        <i className="fas fa-arrow-right footerLinkSingleIco"></i>
                                    </div>
                                    <div className="footerLinkSingleLable">
                                        <p className="footerLinkSingleLableTxt">services</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/testimonials">
                                <div className={location.pathname == "/testimonials" ? "footerLinkSingle active" : "footerLinkSingle"}>
                                    <div className="footerLinkSingleIcon">
                                        <i className="fas fa-arrow-right footerLinkSingleIco"></i>
                                    </div>
                                    <div className="footerLinkSingleLable">
                                        <p className="footerLinkSingleLableTxt">testimonials</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/terms-and-conditions">
                                <div className={location.pathname == "/terms-and-conditions" ? "footerLinkSingle active" : "footerLinkSingle"}>
                                    <div className="footerLinkSingleIcon">
                                        <i className="fas fa-arrow-right footerLinkSingleIco"></i>
                                    </div>
                                    <div className="footerLinkSingleLable">
                                        <p className="footerLinkSingleLableTxt">terms and conditions</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/cart">
                                <div className={location.pathname == "/cart" ? "footerLinkSingle active" : "footerLinkSingle"}>
                                    <div className="footerLinkSingleIcon">
                                        <i className="fas fa-arrow-right footerLinkSingleIco"></i>
                                    </div>
                                    <div className="footerLinkSingleLable">
                                        <p className="footerLinkSingleLableTxt">cart</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/contact-us">
                                <div className={location.pathname == "/contact-us" ? "footerLinkSingle active" : "footerLinkSingle"}>
                                    <div className="footerLinkSingleIcon">
                                        <i className="fas fa-arrow-right footerLinkSingleIco"></i>
                                    </div>
                                    <div className="footerLinkSingleLable">
                                        <p className="footerLinkSingleLableTxt">contact us</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="footerAboutSection" data-aos="fade-up">
                        <div className="footerAboutLogo">
                            <div className="footerAboutLogoImage">
                                <img src={`${BASE_URL}images/logo-header.png`} className="footerAboutLogoImg" />
                            </div>
                        </div>
                        <div className="footerAboutDetails">
                            <div className="footerAboutDetailsHeading">
                                <p className="footerAboutDetailsHeadingTxt">nagarbhavi, bangalore - 560091</p>
                            </div>
                            <div className="footerAboutDetailContact">
                                <div className="footerAboutDetailContactStep">
                                    <div className="footerAboutDetailContactStepLable">
                                        <p className="footerAboutDetailContactStepLableTxt">hotline:</p>
                                    </div>
                                    <div className="footerAboutDetailContactStepValue">
                                        <p className="footerAboutDetailContactStepValueTxt">+91 80 2390 1652</p>
                                    </div>
                                </div>
                                <div className="footerAboutDetailContactStep">
                                    <div className="footerAboutDetailContactStepLable">
                                        <p className="footerAboutDetailContactStepLableTxt">email ID:</p>
                                    </div>
                                    <div className="footerAboutDetailContactStepValue">
                                        <p className="footerAboutDetailContactStepValueTxt email">support@blackbox.pizza</p>
                                    </div>
                                </div>
                            </div>
                            <div className="footerAboutDetailFollow">
                                <div className="footerAboutDetailFollowHeading">
                                    <p className="footerAboutDetailFollowHeadingTxt">follow us on:</p>
                                </div>
                                <div className="footerAboutDetailFollowLinks">
                                    <div className="footerAboutDetailFollowLinksInner">
                                        <a className="footerAboutDetailFollowLinkSingle" href="https://www.facebook.com/blackboxpizza" rel="noreferrer" target="_blank">
                                            <i className="fab fa-facebook-f footerAboutDetailFollowLinkSingleIco"></i>
                                        </a>
                                        <a className="footerAboutDetailFollowLinkSingle" href="https://www.instagram.com/blackbox.pizza/" rel="noreferrer" target="_blank">
                                            <i className="fab fa-instagram footerAboutDetailFollowLinkSingleIco"></i>
                                        </a>
                                        <a className="footerAboutDetailFollowLinkSingle" href="https://wa.me/919986580893" rel="noreferrer" target="_blank">
                                            <i className="fab fa-whatsapp footerAboutDetailFollowLinkSingleIco"></i>
                                        </a>
                                        <a className="footerAboutDetailFollowLinkSingle" href="https://goo.gl/maps/HJNwSKa3u6BUFzT47" rel="noreferrer" target="_blank">
                                            <i className="fas fa-map-marker-alt footerAboutDetailFollowLinkSingleIco"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footerOpeningHourSection" data-aos="fade-left">
                        <div className="footerOpeningHourSectionHeading">
                            <p className="footerOpeningHourSectionHeadingTxt">opening hours</p>
                        </div>

                        <div className="footerOpeningHours">
                            <div className="footerOpeningHourSingle">
                                <div className="footerOpeningHourSingleLable">
                                    <p className="footerOpeningHourSingleLableTxt">sunday:</p>
                                </div>
                                <div className="footerOpeningHourSingleValue">
                                    <p className="footerOpeningHourSingleValueTxt">11:00 - 23:00</p>
                                </div>
                            </div>
                            <div className="footerOpeningHourSingle">
                                <div className="footerOpeningHourSingleLable">
                                    <p className="footerOpeningHourSingleLableTxt">monday:</p>
                                </div>
                                <div className="footerOpeningHourSingleValue">
                                    <p className="footerOpeningHourSingleValueTxt">11:00 - 23:00</p>
                                </div>
                            </div>
                            <div className="footerOpeningHourSingle">
                                <div className="footerOpeningHourSingleLable">
                                    <p className="footerOpeningHourSingleLableTxt">tuesday:</p>
                                </div>
                                <div className="footerOpeningHourSingleValue">
                                    <p className="footerOpeningHourSingleValueTxt">11:00 - 23:00</p>
                                </div>
                            </div>
                            <div className="footerOpeningHourSingle">
                                <div className="footerOpeningHourSingleLable">
                                    <p className="footerOpeningHourSingleLableTxt">wednesday:</p>
                                </div>
                                <div className="footerOpeningHourSingleValue">
                                    <p className="footerOpeningHourSingleValueTxt">11:00 - 23:00</p>
                                </div>
                            </div>
                            <div className="footerOpeningHourSingle">
                                <div className="footerOpeningHourSingleLable">
                                    <p className="footerOpeningHourSingleLableTxt">thursday:</p>
                                </div>
                                <div className="footerOpeningHourSingleValue">
                                    <p className="footerOpeningHourSingleValueTxt">11:00 - 23:00</p>
                                </div>
                            </div>
                            <div className="footerOpeningHourSingle">
                                <div className="footerOpeningHourSingleLable">
                                    <p className="footerOpeningHourSingleLableTxt">friday:</p>
                                </div>
                                <div className="footerOpeningHourSingleValue">
                                    <p className="footerOpeningHourSingleValueTxt">11:00 - 23:00</p>
                                </div>
                            </div>
                            <div className="footerOpeningHourSingle">
                                <div className="footerOpeningHourSingleLable">
                                    <p className="footerOpeningHourSingleLableTxt">saturday:</p>
                                </div>
                                <div className="footerOpeningHourSingleValue">
                                    <p className="footerOpeningHourSingleValueTxt">11:00 - 23:00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footerDesignedCopyright">
                    <div className="footerCopyright">
                        <p className="footerDesignedTxt">copyright &copy; 2022, all rights reserved blackbox pizza.</p>
                    </div>
                    <div className="footerDesigned">
                        <p className="footerDesignedTxt">designed &amp; delivered by: <span className="footerDesignedHighlightTxt">LookitLive</span>.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
import React, { useState } from 'react';
import axios from 'axios';
import { notifyError, notifySuccess } from '../utils/toastify';
import { API_BASE_URL } from '../config';
import { callSubscribeAPI } from '../utils/conversionApiEvents';

const SubscribeNewsletter = () => {
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    
    const validateEmail =  (email) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(email) == false) {
            return false;
        }
        return true;
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value.trim());
        setIsEmailValid(!!e.target.value.trim() && validateEmail(e.target.value.trim()) )
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsEmailValid(!!email)
        if (!!email && isEmailValid) {
            const data = {
                email_id: email
            }

            try {
                const saveContactFormData = await axios.post(`${API_BASE_URL}newsletter`, data);
                if(saveContactFormData.status === 201){
                    callSubscribeAPI();
                    setEmail("");
                    notifySuccess("Request successfully submitted.");
                }
                else if(saveContactFormData.status === 200){
                    setEmail("");
                    notifyError("Email ID already exist.");
                }
                else{
                    notifyError("Something went wrong. Please try again.");
                }
            }
            catch (error) {
                notifyError("Something went wrong. Please try again.");
            }
        }
    }
    
    return (
        <div className="subscribeNewsletter">
            <div className="subscribeNewsletterInner">
                <div className="subscribeNewsletterContent" data-aos="fade-right">
                    <div className="subscribeNewsletterContentHeading">
                        <p className="subscribeNewsletterContentHeadingTxt">Subscribe To Our Newsletter</p>
                    </div>
                    <div className="subscribeNewsletterContentDescription">
                        <p className="subscribeNewsletterContentDescriptionTxt">Dont miss any upcoming event &amp; Get your coupon via email.</p>
                    </div>
                </div>
                    <div className="subscribeNewsletterForm" data-aos="fade-left">
                        <div className="subscribeNewsletterFormInner">
                        <form onSubmit={onSubmit}>
                            <div className="subscribeNewsletterFormInput">
                                <input type="text" className="subscribeNewsletterFormInputTxt" placeholder="Enter your Email ID" value={email} onChange={(e) => handleEmailChange(e)}/>
                            </div>
                            <div className="subscribeNewsletterFormButton">
                                <input type="submit" className="subscribeNewsletterFormButtonInput"  />
                            </div>
                        </form>
                        {
                            isEmailValid
                                ? null : <p className="customizePopUpFormErrorTxt">Invalid email</p>      
                        }
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default SubscribeNewsletter
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import Footer from '../components/Footer'
import Header from '../components/Header'
import Sitemap from '../components/Sitemap'
import Reviews from '../components/Reviews'
import SubscribeNewsletter from '../components/SubscribeNewsletter'
import { useSelector } from 'react-redux';
import WheelComponent from 'react-wheel-of-prizes'
import LoginForm from '../components/LoginForm';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { Link } from 'react-router-dom';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const SpinTheWheelPage = () => {

    const user = useSelector((state) => state.user.value);

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    useEffect(() => {
        AOS.init({
            duration : 1000
        });
        if(user.is_logged_in === true){
            checkEligiblityToSpinTheWheelHandler();
        }
    }, []);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const [isShowLogin, setIsShowLogin] = useState(user.is_logged_in === false ? true : false);
    const [isEligibleToTry, setIsEligibleToTry] = useState(null);
    const [isShowTryYourLuck, setIsShowTryYourLuck] = useState(user.is_logged_in);


    const [spinResult, setSpinResult] = useState("");
    const [isWonCoupon, setIsWonCoupon] = useState(false);
    const [isWonIphone, setIsWonIphone] = useState(false);
    const [isBetterLuckNextTime, setIsBetterLuckNextTime] = useState(false);

    const getLoginStatus = (e) => {
        if(e.is_logged_in === true){
            setIsShowLogin(false);
            checkEligiblityToSpinTheWheelHandler();
        }
        else{
            setIsShowLogin(true);
        }
    }

    const checkEligiblityToSpinTheWheelHandler = async () => {
        const checkEligiblityReq = await axios.get(`${API_BASE_URL}customer/check/spin/eligiblity/${user.id}`, config);
        if(checkEligiblityReq.status === 200){
            setIsEligibleToTry(true);
        }
        else{
            setIsEligibleToTry(false);
        }
    }

    const segments = [
        'Won iPhone 13',
        'Won 10% Off',
        'Won 25% Off',
        'Better Luck Next Time',
        'Won 25% Off',
        'Won 50% Off',
        'Better Luck Next Time',
        'Won 30% Off'
    ]

    const winning_segments = [
        'Won 10% Off',
        'Won 25% Off',
        'Better Luck Next Time',
        'Won 25% Off',
        'Won 50% Off',
        'Better Luck Next Time',
        'Won 30% Off'
    ]

    const random = Math.floor(Math.random() * winning_segments.length);
    const won = winning_segments[random];

    const segColors = [
        '#EE4040',
        '#F0CF50',
        '#815CD1',
        '#3DA5E0',
        '#34A24F',
        '#F9AA1F',
        '#EC3F3F',
        '#FF9000'
    ];

    const saveThisSpinResultsHandler = async (result) => {
        const data = {
            id: user.id,
            result: result
        }
        try{
            const saveScanToWinResultReq = await axios.post(`${API_BASE_URL}customer/save/spin`, data, config);
            if(saveScanToWinResultReq.status === 200){
                if(result === "BBFLAT10" || result === "BBFLAT25" || result === "BBFLAT30" || result === "BBFLAT50"){
                    setIsEligibleToTry(false);
                    setIsWonCoupon(true);
                    setSpinResult(result);
                }
                else if(result === "BLNT"){
                    setIsEligibleToTry(false);
                    setIsBetterLuckNextTime(true);
                    setSpinResult(result);
                }
                else if(result === "IPHONE13"){
                    setIsEligibleToTry(false);
                    setIsWonIphone(true);
                    setSpinResult(result);
                }
                else{
                    console.log("");
                }
            }
            else{
                console.log("error");
            }
        }
        catch(error){
            console.log(error);
        }
    }
    
    const onFinished = async (winner) => {
        if(winner == "Won 10% Off"){
            const data = {
                coupon: "BBFLAT10"
            }
            try{
                const saveScanToWinCouponReq = await axios.post(`${API_BASE_URL}coupon/public/user/add`, data, config);
                if(saveScanToWinCouponReq.status === 200){
                    saveThisSpinResultsHandler("BBFLAT10");
                }
            }
            catch(error){
                console.log(error);
            }
        }
        else if(winner == "Won 25% Off"){
            const data = {
                coupon: "BBFLAT25"
            }
            try{
                const saveScanToWinCouponReq = await axios.post(`${API_BASE_URL}coupon/public/user/add`, data, config);
                saveThisSpinResultsHandler("BBFLAT25");
            }
            catch(error){
                console.log(error);
            }
        }
        else if(winner == "Won 30% Off"){
            const data = {
                coupon: "BBFLAT30"
            }
            try{
                const saveScanToWinCouponReq = await axios.post(`${API_BASE_URL}coupon/public/user/add`, data, config);
                saveThisSpinResultsHandler("BBFLAT30");
            }
            catch(error){
                console.log(error);
            }
        }
        else if(winner == "Won 50% Off"){
            const data = {
                coupon: "BBFLAT50"
            }
            try{
                const saveScanToWinCouponReq = await axios.post(`${API_BASE_URL}coupon/public/user/add`, data, config);
                saveThisSpinResultsHandler("BBFLAT50");
            }
            catch(error){
                console.log(error);
            }
        }
        else if("Better Luck Next Time"){
            saveThisSpinResultsHandler("BLNT");
        }
        else{
            console.log("iPhone")
        }
    }

    return (
        <>
            <Header />
            <Sitemap pageName="scan & win iPhone 13" pagePath="scan-and-win-iphone-13" />

            {
                isShowLogin === true
                ?
                    <div className="checkoutPage">
                        <div className="checkoutPageLoginForm">
                            <LoginForm getLoginStatus={(e) => {getLoginStatus(e)}} />
                        </div>
                    </div>
                :
                <></>
            }

            {
                isEligibleToTry === true
                ?
                <>
                    <div className="spinTheWheelContainer">
                        <div className="spinTheWheelContainerInner">
                            <WheelComponent
                                segments={segments}
                                segColors={segColors}
                                winningSegment={won}
                                onFinished={(winner) => onFinished(winner)}
                                primaryColor='black'
                                contrastColor='white'
                                buttonText='Spin'
                                isOnlyOnce={false}
                                size={windowDimensions.width > 500 ? 290 : 290}
                                upDuration={100}
                                downDuration={1000}
                                fontFamily='Arial'
                            />
                        </div>
                    </div>
                </>
                :
                isEligibleToTry === false
                ?
                <>
                    <div className="cartPage">
                        {
                            isWonCoupon === true
                            ?
                                <div className="cartPageDetails">
                                    <div className="cartPageDetailsInner">
                                        <div className="emptyCart">
                                            <div className="emptyCartInner">
                                                <div className="emptyCartVisual">
                                                    <div className="emptyCartVisualIcon">
                                                        <i className="fa-solid fa-gift"></i>
                                                    </div>
                                                </div>
                                                <div className="emptyCartContent">
                                                    <div className="emptyCartContentHeading">
                                                        <p className="emptyCartContentHeadingTxt">You have won a coupon {spinResult}!</p>
                                                    </div>
                                                    <div className="emptyCartContentDescription">
                                                        <p className="emptyCartContentDescriptionTxt">Avail {spinResult === "BBFLAT10" ? "10% off" : spinResult === "BBFLAT25" ? "25% off" : spinResult === "BBFLAT30" ? "30% off" : spinResult === "BBFLAT50" ? "50% off" : ""} on your next online or store order by redeeming the {spinResult} coupon code.</p>
                                                    </div>

                                                    <div className="emptyCartActions">
                                                        <div className="emptyCartActionsInner">
                                                            <div className="emptyCartActionSingle">
                                                                <Link to="/menu">
                                                                    <p className="emptyCartActionSingleTxt">redeem now</p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            isWonIphone === true
                            ?
                                <div className="cartPageDetails">
                                    <div className="cartPageDetailsInner">
                                        <div className="emptyCart">
                                            <div className="emptyCartInner">
                                                <div className="emptyCartVisual">
                                                    <div className="emptyCartVisualIcon">
                                                        <i className="fa-regular fa-face-grin-hearts"></i>
                                                    </div>
                                                </div>
                                                <div className="emptyCartContent">
                                                    <div className="emptyCartContentHeading">
                                                        <p className="emptyCartContentHeadingTxt">Hurray, You have won an iPhone 13!</p>
                                                    </div>
                                                    <div className="emptyCartContentDescription">
                                                        <p className="emptyCartContentDescriptionTxt">We are greatful to know that you are the lucky one. Take a screenshot of this message and visit our store to get your iPhone 13 now.</p>
                                                    </div>

                                                    <div className="emptyCartActions">
                                                        <div className="emptyCartActionsInner">
                                                            <div className="emptyCartActionSingle">
                                                                <Link to="/">
                                                                    <p className="emptyCartActionSingleTxt">back to home</p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            isBetterLuckNextTime === true
                            ?
                            <div className="cartPageDetails">
                                <div className="cartPageDetailsInner">
                                    <div className="emptyCart">
                                        <div className="emptyCartInner">
                                            <div className="emptyCartVisual">
                                                <div className="emptyCartVisualIcon">
                                                    <i className="fa-solid fa-hourglass-start"></i>
                                                </div>
                                            </div>
                                            <div className="emptyCartContent">
                                                <div className="emptyCartContentHeading">
                                                    <p className="emptyCartContentHeadingTxt">Sorry, Better luck next time.</p>
                                                </div>
                                                <div className="emptyCartContentDescription">
                                                    <p className="emptyCartContentDescriptionTxt">Your luck wasn't in your favour today. You don't need to worry as you can try again on your next order.</p>
                                                </div>

                                                <div className="emptyCartActions">
                                                    <div className="emptyCartActionsInner">
                                                        <div className="emptyCartActionSingle">
                                                            <Link to="/">
                                                                <p className="emptyCartActionSingleTxt">back to home</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="cartPageDetails">
                                <div className="cartPageDetailsInner">
                                    <div className="emptyCart">
                                        <div className="emptyCartInner">
                                            <div className="emptyCartVisual">
                                                <div className="emptyCartVisualIcon">
                                                    <i className="fa-solid fa-clock-rotate-left"></i>
                                                </div>
                                            </div>
                                            <div className="emptyCartContent">
                                                <div className="emptyCartContentHeading">
                                                    <p className="emptyCartContentHeadingTxt">Sorry, You have reached your daily limit.</p>
                                                </div>
                                                <div className="emptyCartContentDescription">
                                                    <p className="emptyCartContentDescriptionTxt">Sorry, you have reached your daily limit to test your luck by spinning the wheel. You can try again on your next order after 24 hours.</p>
                                                </div>

                                                <div className="emptyCartActions">
                                                    <div className="emptyCartActionsInner">
                                                        <div className="emptyCartActionSingle">
                                                            <Link to="/">
                                                                <p className="emptyCartActionSingleTxt">back to home</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
                :
                <></>
            }
            
            
            <Reviews />
            <SubscribeNewsletter />
            <Footer />
        </>
    )
}

export default SpinTheWheelPage
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from '../utils/toastify';
import axios from 'axios';
import { API_BASE_URL, LOGGER } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct, clear } from '../reducers/cart';

const CustomizeProductPopUp = ({product, completeCustomization}) => {
    let { id } = useParams(); 
    console.log(product);

    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart.value);

    const [sizes, setSizes] = useState([]);
    const [variants, setVariants] = useState([]);

    const completeSelectionHandler = async (status) => {
        completeCustomization(status);
    }

    const formateSizes = () => {
        if(product.sizes){
            if(product.sizes.length > 0){
                const temp_sizes = [];

                product.sizes.map((size) => {
                    const temp_size = {
                        ...size,
                        is_online_selected: size.is_online_default ? true : false
                    }
                    temp_sizes.push(temp_size);
                });

                temp_sizes.map((size) => {
                    const temp_crusts = [];

                    size.crusts.map((crust) => {
                        const temp_crust = {
                            ...crust,
                            is_online_selected: crust.is_online_default ? true : false
                        }
                        temp_crusts.push(temp_crust);
                    });

                    size.crusts = temp_crusts;
                });

                setSizes(temp_sizes);
            }
            else{
                setSizes([]);
            }
        }
        else{
            setSizes([]);
        }
    }

    useEffect(() => {
        formateSizes();
    }, []);

    const formateVariants = () => {
        if(product.variants){
            if(product.variants.length > 0){
                const temp_variants = [];

                product.variants.map((variant) => {
                    const temp_types = [];

                    variant.types.map((type) => {
                        const temp_type = {
                            ...type,
                            is_online_selected: type.is_online_default ? true : false
                        }
                        temp_types.push(temp_type);
                    });

                    variant.types = temp_types;
                    temp_variants.push(variant);
                });

                setVariants(temp_variants);

                console.log(temp_variants);
            }
            else{
                setVariants([]);
            }
        }
        else{
            setVariants([]);
        }
    }

    useEffect(() => {
        formateVariants();
    }, []);

    const markSizeCrustSelectedHandler = (sizeId, crustId) => {
        const temp_sizes = [];

        sizes.map((size) => {

            const temp_size = {
                ...size,
                is_online_selected: size.id === sizeId ? true : false
            }
            temp_sizes.push(temp_size);
        });

        temp_sizes.map((size) => {
            const temp_crusts = [];

            size.crusts.map((crust) => {
                const temp_crust = {
                    ...crust,
                    is_online_selected: crust.id === crustId ? true : false
                }
                temp_crusts.push(temp_crust);
            });

            size.crusts = temp_crusts;
        });

        setSizes(temp_sizes);
    }

    useEffect(() => {
        console.log(sizes);
    }, [sizes]);

    const changeSizeCrustHandler = (sizeId, crustId) => {
        const currentSelectedSize = sizes.find(size => size.is_online_selected === true);

        if(currentSelectedSize.id === sizeId){
            const currentSelectedCrust = currentSelectedSize.crusts.find(crust => crust.is_online_selected === true);

            if(currentSelectedCrust.id === crustId){
                notifyError('Please Choose a Different Crust');
                return
            }
            else{
                if(currentSelectedSize.in_stock_online === false){
                    notifyError('Product Out of Stock');
                    return
                }
                else{
                    const choosenCrust = currentSelectedSize.crusts.find(crust => crust.id === crustId);
                    if(choosenCrust.in_stock_online === false){
                        notifyError('Product Out of Stock');
                        return
                    }
                    else{
                        markSizeCrustSelectedHandler(sizeId, crustId);
                    }
                }
            }
        }
        else{
            const choosenSize = sizes.find(size => size.id === sizeId);

            if(choosenSize.in_stock_online === false){
                notifyError('Product Out of Stock');
                return
            }
            else{
                const choosenCrust = choosenSize.crusts.find(crust => crust.id === crustId);
                console.log(choosenCrust);
                if(choosenCrust.in_stock_online === false){
                    notifyError('Product Out of Stock');
                    return
                }
                else{
                    markSizeCrustSelectedHandler(sizeId, crustId);
                }
            }
        }
    }

    const selectThisVariantHandler = (variantId, typeId) => {
        const temp_variants = []

        variants.map((variant) => {
            let this_variant = variant;
            let this_variant_types = [];
            if(variant.id === variantId){
                variant.types.map((type) => {
                    if(type.id === typeId){
                        let this_type = {
                            ...type,
                            is_online_selected: true
                        }
                        this_variant_types.push(this_type);
                    }
                    else{
                        this_variant_types.push(type);
                    }
                });

                this_variant.types = this_variant_types;
                temp_variants.push(this_variant);
            }
            else{
                temp_variants.push(this_variant);
            }
        });

        setVariants(temp_variants);
    }

    const deselectThisVariantHandler = (variantId, typeId) => {
        const temp_variants = []

        variants.map((variant) => {
            let this_variant = variant;
            let this_variant_types = [];
            if(variant.id === variantId){
                variant.types.map((type) => {
                    if(type.id === typeId){
                        let this_type = {
                            ...type,
                            is_online_selected: false
                        }
                        this_variant_types.push(this_type);
                    }
                    else{
                        this_variant_types.push(type);
                    }
                });

                this_variant.types = this_variant_types;
                temp_variants.push(this_variant);
            }
            else{
                temp_variants.push(this_variant);
            }
        });

        setVariants(temp_variants);
    }

    const variantSelectionHandler = (variantId, typeId, newSelection) => {
        const choosenVariant = variants.find(variant => variant.id === variantId);
        console.log(choosenVariant);

        let currentSelectionCount = 0;

        choosenVariant.types.map(type => {
            if(type.is_online_selected === true){
                currentSelectionCount = currentSelectionCount + 1;
            }
        });

        const choosenType = choosenVariant.types.find(type => type.id === typeId);

        if(newSelection === true){
            if(choosenVariant.max_selection > currentSelectionCount){
                if(choosenType.in_stock_online === true){
                    selectThisVariantHandler(variantId, typeId);
                }
                else{
                    notifyError(`Product Not in Stock`);
                    return
                }
            }
            else{
                notifyError(`You can choose upto ${choosenVariant.max_selection} ${choosenVariant.lable}`);
                return
            }
        }
        else{
            console.log(choosenType);
            if(choosenType.is_online_mandatory === true){
                notifyError(`${choosenType.name} is mandatory for this product!`);
                return
            }
            else{
                if(choosenVariant.min_selection < currentSelectionCount){
                    deselectThisVariantHandler(variantId, typeId);
                }
                else{
                    notifyError(`Min ${choosenVariant.min_selection} ${choosenVariant.lable} is required`);
                    return
                }
            }
        }
    }


    const addThisProduct = async () => {

        let product_data = {
            id: Date.now(),
            product: product.id,
            category: product.category.id,
            quantity: 1,
            size: {},
            variants : [],
            added_using_coupon: false
        }

        let size_data = {
            pizza_size_id: '',
            crust: {
                pizza_crust_id: ''
            }
        }

        const selectedSize = sizes.find(size => size.is_online_selected === true);
        const selectedCrust = selectedSize.crusts.find(crust => crust.is_online_selected === true);

        size_data.pizza_size_id = selectedSize.pizza_size.id;
        size_data.crust.id = selectedCrust.id;

        product_data.size = size_data;

        variants.map((variant) => {
            let variant_data = {
                variant_type_id: variant.id,
                value: []
            }

            variant.types.map(type => {
                let type_data = {
                    variant_id: type.id,
                    is_default: type.is_online_default,
                    is_selected: type.is_online_selected
                }

                variant_data.value.push(type_data);
            });
            
            product_data.variants.push(variant_data);
        });

        saveThisProductToOrder(product_data);
    }

    const saveThisProductToOrder = async (product_data) => {
        dispatch(addProduct(product_data));
        completeCustomization(true);
    }

    return (
        <div className="posPopUp">
            <div className="posPopUpOverlay">&nbsp;</div>
            <div className="posPopUpMain">
                <div className="posPopUpInner">
                    <div className="posPopUpHeading">
                        <p className="posPopUpHeadingTxt">Customize Product</p>
                    </div>

                    <div className="posPopUpContent">
                        <div className="posPopUpContentMain">
                            <div className="posPopUpContentInner">
                                {
                                    sizes.length > 0 
                                    ?
                                    <div className="posPopUpContentSizeSection">
                                        <div className="posPopUpContentSizeSectionHeading">
                                            <p className="posPopUpContentSizeSectionHeadingTxt">size</p>
                                        </div>
                                        <div className="posPopUpContentSizeSectionContent">
                                            {
                                                sizes.map((size, i) => {
                                                    return (
                                                        <div className="posPopUpContentSizeSectionContentSingle" key={size.id}>
                                                            <div className="posPopUpContentSizeSectionContentSingleHeading">
                                                                <p className="posPopUpContentSizeSectionContentSingleHeadingTxt">{size.pizza_size.name}</p>
                                                            </div>
                                                            <div className="posPopUpContentSizeSectionContentSingleCrusts">
                                                                {
                                                                    size.crusts.map((crust, i) => {
                                                                        console.log(crust)
                                                                        return (
                                                                            <div className={`posPopUpContentSizeSectionContentSingleCrustSingle ${crust.is_online_selected ? 'selected' : <></>}`} key={crust.id} onClick={() => changeSizeCrustHandler(size.id, crust.id)}>
                                                                                <p className="posPopUpContentSizeSectionContentSingleCrustSingleTxt">{crust.pizza_crust.name}</p>
                                                                                {
                                                                                    crust.price.base.online != '0'
                                                                                    ?
                                                                                    <>
                                                                                        <span className="posPopUpContentSizeSectionContentSingleCrustSingleSeperator">-</span>
                                                                                        <div className="posPopUpContentSizeSectionContentSingleCrustSinglePrice">
                                                                                            <i className="fa-solid fa-indian-rupee-sign posPopUpContentSizeSectionContentSingleCrustSinglePriceIco"></i>
                                                                                            <p className="posPopUpContentSizeSectionContentSingleCrustSinglePriceTxt">{crust.price.base.online}</p>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            
                                        </div>
                                    </div>
                                    :
                                        <></>
                                }
                                <div className="posPopUpContentVariantSection">
                                    {
                                        variants.map((variant, i) => {
                                            return (
                                                <div className="posPopUpContentVariantSectionSingle" key={variant.id}>
                                                    <div className="posPopUpContentVariantSectionHeading">
                                                        <p className="posPopUpContentVariantSectionHeadingTxt">{variant.lable}</p>
                                                    </div>
                                                    <div className="posPopUpContentVariantSectionContent">
                                                        {
                                                            variant.types.map((type, i) => {
                                                                return (
                                                                    <div key={type.id} className={`posPopUpContentVariantSectionContentSingle ${type.is_online_selected ? 'selected' : <></>}`} onClick={() => variantSelectionHandler(variant.id, type.id, type.is_online_selected ? false : true)}>
                                                                        <p className="posPopUpContentVariantSectionContentSingleTxt">{type.name}</p>
                                                                        {
                                                                            type.price.base.online != 0
                                                                            ?
                                                                            <>
                                                                                <span className="posPopUpContentVariantSectionContentSingleSeperator">-</span>
                                                                                <div className="posPopUpContentVariantSectionContentSinglePrice">
                                                                                    <i className="fa-solid fa-indian-rupee-sign posPopUpContentVariantSectionContentSinglePriceIco"></i>
                                                                                    <p className="posPopUpContentVariantSectionContentSinglePriceTxt">{type.price.base.online}</p>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="posPopUpContentActions">
                            <div className="posPopUpContentActionsInner">
                                <button className="posPopUpContentActionSingle" onClick={() => completeSelectionHandler(false)}>close</button>
                                <button className="posPopUpContentActionSingle" onClick={() => addThisProduct()}>confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomizeProductPopUp
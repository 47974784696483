import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import verifyToken from './verify-jwt';
import { ROLES, LOGGER } from './config';


import HomePage from './pages/HomePage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import MenuPage from './pages/MenuPage';
import OrderDetailsPage from './pages/OrderDetails';
import UserProfile from './pages/UserProfilePage';
import KotPrint from './pages/KotPage';
import SpinTheWheelPage from './pages/SpinTheWheelPage';

const AppRouter = () => {
    const [auth, setAuth] = useState(null);
  
    const user = useSelector((state) => state.user.value);

    useEffect(() => {
        async function checkIsLoggedIn() {
            if(user.is_logged_in === true){
                const checkToken = await verifyToken(user.access_token);
                setAuth(checkToken.status);
            }
            else{
                setAuth(false);
            }
        }
        checkIsLoggedIn();
    }, [user]);

    return (
        <Routes>
        {/* //     <Route path="/kot-print/:id" element={<KotPrint />} />
        //     <Route path="/services" element={<ServicesPage />} />
        //     
        //     <Route path="/testimonials" element={<TestimonialsPage />} />
        //     <Route path="/check-your-luck" element={<TestYourLuckPage />} />
        //     <Route path="/terms-and-conditions" element={<TermsConditionsPage />} /> */}

            <Route path="/scan-and-win-iphone-13" element={<SpinTheWheelPage />} />
            <Route path="/kot-print/:id" element={<KotPrint />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/order/:id" element={<OrderDetailsPage />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/about-us" element={<AboutPage />} />
            <Route path="/" element={<HomePage />} />
        </Routes>
    )
}

export default AppRouter;
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import Header from '../components/Header';
import Sitemap from '../components/Sitemap';
import SubscribeNewsletter from '../components/SubscribeNewsletter';
import Footer from '../components/Footer';
import LoginForm from '../components/LoginForm';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, BASE_URL } from '../config';
import { login } from '../reducers/user';
import { notifyError, notifySuccess } from '../utils/toastify';

import io from 'socket.io-client';
import { SOCKET_PATH, SOCKET_URL } from '../config';

const socket = io(SOCKET_URL, {path: SOCKET_PATH, 'transports': ['websocket', 'polling']});

const OrderDetailsPage = () => {
    let { id } = useParams(); 
    const user = useSelector((state) => state.user.value);

    const [order, setOrder] = useState();
    const [showOrder, setShowOrder] = useState(false);
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showNoOrderFound, setShowNoOrderFound] = useState(false);
    const [locDate, setLocDate] = useState();
    const [dispDate, setDispDate] = useState();

    useEffect(() => {
        AOS.init({
            duration : 1000
        });

    }, []);

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const getThisOrder = async () => {
        
        const getThisOrderReq = await axios.get(`${API_BASE_URL}order/online/${id}`, config);
        if(getThisOrderReq.status === 200){
            setOrder(getThisOrderReq.data);

            const localDate = new Date(getThisOrderReq.data.ordered_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
            setDispDate(moment(localDate).format('Do MMMM YYYY, h:mm a'));
            
            if(getThisOrderReq.data.status === "DELIVERED") {
                setShowLoading(false);
                setShowNoOrderFound(false);
                setShowOrder(false);
                setShowOrderDetails(true);
            }
            else{
                setShowLoading(false);
                setShowNoOrderFound(false);
                setShowOrderDetails(false);
                setShowOrder(true);
            }
        }
        else if(getThisOrderReq.status === 201){
            setShowLoading(false);
            setShowOrder(false);
            setShowNoOrderFound(true);
        }
        else{
            setShowLoading(false);
            setShowOrder(false);
            setShowNoOrderFound(false);
            alert("Something Went Wrong");
        }
    }

    useEffect( () => {
        getThisOrder();
    }, []);

    const handleErrors = (err) => {
        console.log(err);
    }

    useEffect(() => {
        socket.on('connect_error', err => handleErrors(err))
        socket.on('connect_failed', err => handleErrors(err))
        socket.on('disconnect', err => handleErrors(err))
        socket.emit("join_room", id);
    }, []);

    useEffect(() => {
        socket.on("accepted", () => {
            getThisOrder();
        });
        socket.on("rejected", () => {
            getThisOrder();
        });
        socket.on("cancelled", () => {
            getThisOrder();
        });
        socket.on("ready", () => {
            getThisOrder();
        });
        socket.on("sent", () => {
            getThisOrder();
        });
        socket.on("delivered", () => {
            getThisOrder();
        });
    }, [socket]);

    const rateThisOrder = async (order_id, rating) => {

        const data = {
            rating: rating
        }

        const rateThisOrderReq = await axios.patch(`${API_BASE_URL}order/rate/${order_id}`, data, config);
        if(rateThisOrderReq.status === 200){
            notifySuccess("Thank You For Rating The Order!");
            getThisOrder();
        }
    }
    
    return (
        <>
            <Header />
            <Sitemap pageName={"order details"} pagePath={"order"} />
            
            {
                showOrder === true
                ?
                    <div className="orderPage">
                        <div className="orderPageInner">
                            <div className="orderPageSummaryVisual">
                                <div className="orderPageSummaryVisualImage">
                                    <img src="/images/order-summary-delivery.png" className="orderPageSummaryVisualImg" />
                                </div>
                            </div>

                            <div className="orderPageSummaryEstimatedTime">
                                <div className="orderPageSummaryEstimatedTimeInner">
                                    <div className="orderPageSummaryEstimatedTimeIcon">
                                        <i className="fas fa-clock orderPageSummaryEstimatedTimeIco"></i>
                                    </div>
                                    <div className="orderPageSummaryEstimatedTimeContent">
                                        <p className="orderPageSummaryEstimatedTimeContentTxt">Estimated Delivery Time: 20 mins</p>
                                    </div>
                                </div>
                            </div>

                            <div className="orderPageSummaryTimeline">
                                <div className="orderPageSummaryTimelineInner">
                                    <div className="orderPageSummaryTimelineSingle">
                                        <div className="orderPageSummaryTimelineSingleInner">
                                            <div className="orderPageSummaryTimelineSingleVisual">
                                                <div className={order.status === "RECEIVED" ? "orderPageSummaryTimelineSingleVisualMain finished" : "orderPageSummaryTimelineSingleVisualMain"}>
                                                    <div className="orderPageSummaryTimelineSingleVisualMainLoading"></div>
                                                </div>
                                            </div>

                                            <div className="orderPageSummaryTimelineSingleContent">
                                                <div className="orderPageSummaryTimelineSingleContentHeading">
                                                    <p className="orderPageSummaryTimelineSingleContentHeadingTxt">order received</p>
                                                </div>
                                                <div className="orderPageSummaryTimelineSingleContentDescription">
                                                    <p className="orderPageSummaryTimelineSingleContentDescriptionTxt">your order has been received</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="orderPageSummaryTimelineSingle">
                                        <div className="orderPageSummaryTimelineSingleInner">
                                            <div className="orderPageSummaryTimelineSingleVisual">
                                                <div className={order.status === "ACCEPTED" ? "orderPageSummaryTimelineSingleVisualMain finished" : "orderPageSummaryTimelineSingleVisualMain"}>
                                                    <div className="orderPageSummaryTimelineSingleVisualMainLoading"></div>
                                                </div>
                                            </div>

                                            <div className="orderPageSummaryTimelineSingleContent">
                                                <div className="orderPageSummaryTimelineSingleContentHeading">
                                                    <p className="orderPageSummaryTimelineSingleContentHeadingTxt">order accepted</p>
                                                </div>
                                                <div className="orderPageSummaryTimelineSingleContentDescription">
                                                    <p className="orderPageSummaryTimelineSingleContentDescriptionTxt">your order has been accepted</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="orderPageSummaryTimelineSingle">
                                        <div className="orderPageSummaryTimelineSingleInner">
                                            <div className="orderPageSummaryTimelineSingleVisual">
                                                <div className={order.status === "SENT" ? "orderPageSummaryTimelineSingleVisualMain finished" : "orderPageSummaryTimelineSingleVisualMain"}>
                                                    <div className="orderPageSummaryTimelineSingleVisualMainLoading"></div>
                                                </div>
                                            </div>

                                            <div className="orderPageSummaryTimelineSingleContent">
                                                <div className="orderPageSummaryTimelineSingleContentHeading">
                                                    {
                                                        order.delivery_type === "TAKEAWAY" 
                                                        ?
                                                        <p className="orderPageSummaryTimelineSingleContentHeadingTxt">ready to pickup</p>
                                                        :
                                                        order.delivery_type === "DELIVERY" 
                                                        ?
                                                        <p className="orderPageSummaryTimelineSingleContentHeadingTxt">out for delivery</p>
                                                        :
                                                        <></>
                                                    }
                                                    
                                                </div>
                                                <div className="orderPageSummaryTimelineSingleContentDescription">
                                                    {
                                                        order.delivery_type === "TAKEAWAY" 
                                                        ?
                                                        <p className="orderPageSummaryTimelineSingleContentDescriptionTxt">Your order is ready. Visit store now.</p>
                                                        :
                                                        order.delivery_type === "DELIVERY" 
                                                        ?
                                                        <p className="orderPageSummaryTimelineSingleContentDescriptionTxt">Hang on! Your food is on the way</p>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <></>
            }

            {
                showOrderDetails === true
                ?
                    <div className="orderPage">
                        <div className="orderPageInner">
                            <div className="myOrdersList">
                                <div className="myOrderSingle">
                                    <div className="myOrderSingleDetails">
                                        <div className="myOrderSingleIdStatus">
                                            <div className="myOrderSingleId">
                                                <p className="myOrderSingleIdTxt">Order ID: {order.order_id}</p>
                                            </div>
                                            <div className="myOrderSingleStatus">
                                                {
                                                    order.is_online === false
                                                    ?
                                                        <p className="myOrderSingleStatusTxt">Store: {order.store.name}</p>
                                                    :
                                                    order.is_online === true && order.delivery_type === "TAKEAWAY" 
                                                    ?
                                                        <p className="myOrderSingleStatusTxt">Picked Up From: {order.store.name} Store</p>
                                                    :
                                                    order.is_online === true && order.delivery_type === "DELIVERY" 
                                                    ?
                                                        <p className="myOrderSingleStatusTxt">Delivered To: Home</p>
                                                    :
                                                        <></>
                                                }
                                            </div>

                                            <div className="myOrderSingleStatusMain">
                                                {
                                                    order.status === "DELIVERED"
                                                    ?
                                                        <p className="myOrderSingleStatusMainTxt delivered">delivered</p>
                                                    :
                                                    order.status === "SENT"
                                                    ?
                                                        <p className="myOrderSingleStatusMainTxt accepted">out for delivery</p>
                                                    :
                                                    order.status === "ACCEPTED"
                                                    ?
                                                        <p className="myOrderSingleStatusMainTxt accepted">accepted</p>
                                                    :
                                                    order.status === "RECEIVED" || order.status === "ORDERED"
                                                    ?
                                                        <p className="myOrderSingleStatusMainTxt ordered">ordered</p>
                                                    :
                                                    order.status === "CANCELLED"
                                                    ?
                                                        <p className="myOrderSingleStatusMainTxt cancelled">cancelled</p>
                                                    :
                                                    order.status === "REJECTED"
                                                    ?
                                                        <p className="myOrderSingleStatusMainTxt cancelled">rejected</p>
                                                    :
                                                        <></>
                                                }
                                                
                                            </div>
                                        </div>

                                        <div className="myOrderSingleItemsTime">
                                            <div className="myOrderSingleItems">
                                                <div className="myOrderSingleItemsLable">
                                                    <p className="myOrderSingleItemsLableTxt">Items:</p>
                                                </div>
                                                <div className="myOrderSingleItemsList">
                                                    <p className="myOrderSingleItemsListTxt">
                                                        {
                                                            order.products.map(product => {
                                                                return(
                                                                    <span className="myOrderSingleItemsListSingle" key={product.id}>
                                                                        <span className="myOrderSingleItemsListContent">{product.quantity} X {product.product.name}</span>
                                                                        <span className="myOrderSingleItemsListComa">,&nbsp;</span>
                                                                        <span className="myOrderSingleItemsListStop">.&nbsp;</span>
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="myOrderSingleTime">
                                                <div className="myOrderSingleTimeLable">
                                                    <p className="myOrderSingleTimeLableTxt">Ordered On:</p>
                                                </div>
                                                <div className="myOrderSingleTimeContent">
                                                    <p className="myOrderSingleTimeContentTxt">{dispDate}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div className="myOrderSingleRatingPrice">
                                        {
                                            order.rating === 0
                                            ?
                                            <div className="myOrderSingleRating">
                                                <div className="myOrderSingleRatingLable">
                                                    <p className="myOrderSingleRatingLableTxt">rate order:</p>
                                                </div>
                                                <div className="myOrderSingleRatingStars">
                                                    <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 1)}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">1</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                    <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 2)}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">2</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                    <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 3)}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">3</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                    <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 4)}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">4</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                    <div className="myOrderSingleRatingStar" onClick={() => rateThisOrder(order.id, 5)}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">5</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="myOrderSingleRating">
                                                <div className="myOrderSingleRatingLable">
                                                    <p className="myOrderSingleRatingLableTxt">You Rated:</p>
                                                </div>
                                                <div className="myOrderSingleRatingStars">
                                                    <div className={order.rating >= 1 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">1</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                    <div className={order.rating >= 2 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">2</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                    <div className={order.rating >= 3 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">3</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                    <div className={order.rating >= 4 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">4</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                    <div className={order.rating >= 5 ? 'myOrderSingleRatingStar ratedTrue' : 'myOrderSingleRatingStar ratedFalse'}>
                                                        <div className="myOrderSingleRatingStarLable">
                                                            <p className="myOrderSingleRatingStarLableTxt">5</p>
                                                        </div>
                                                        <div className="myOrderSingleRatingStarIcon">
                                                            <i className="fas fa-star myOrderSingleRatingStarIco"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                        <div className="myOrderSinglePrice">
                                            <div className="myOrderSinglePriceInner">
                                                <div className="myOrderSinglePriceIcon">
                                                    <i className="fas fa-rupee-sign myOrderSinglePriceIco"></i>
                                                </div>
                                                <div className="myOrderSinglePriceLable">
                                                    <p className="myOrderSinglePriceLableTxt">{order.costing.final_price}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <></>
            }

            <SubscribeNewsletter />
            <Footer />
        </>
    )
}

export default OrderDetailsPage
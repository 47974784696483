import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import axios from 'axios';

import Header from '../components/Header';
import Sitemap from '../components/Sitemap';
import SubscribeNewsletter from '../components/SubscribeNewsletter';
import Footer from '../components/Footer';

import { API_BASE_URL } from '../config';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clear, increaseProductQty, decreaseProductQty, removeCartProduct, removeCoupon} from '../reducers/cart';
import { notifyError } from '../utils/toastify';
import { logout } from '../reducers/user';

const CartPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cart = useSelector((state) => state.cart.value);
    const user = useSelector((state) => state.user.value);

    const [cartProducts, setCartProducts] = useState([]);

    const [cartPricing, setCartPricing] = useState({
        base_price: 0,
        discount: 0,
        discounted_price: 0,
        tax: 0,
        delivery_charges: 0,
        final_price: 0,
        tax_percentage: 5
    });

    const [showCartDetails, setShowCartDetails] = useState(false);
    const [showEmptyCart, setShowEmptyCart] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const clearCart = () => {
        dispatch(clear());
    }

    const clearCoupon = () => {
        dispatch(removeCoupon())
    }

    useEffect(() => {
        AOS.init({
            duration : 1000
        });

        // dispatch(logout());

        clearCoupon();

        getAndSetCartProducts();
    }, []);

    useEffect(() => {
        if(cart.products.length === 0){
            setShowCartDetails(false);
            setShowEmptyCart(true);
        }
        else{
            setShowEmptyCart(false);
            setShowCartDetails(true);
        }
    }, [cart]);

    const getAndSetCartProducts = async () => {
        try{
            const getPricingForCart = await axios.post(`${API_BASE_URL}product/public/cart/pricing`, {products: cart.products});
            setCartProducts(getPricingForCart.data);
            console.log(getPricingForCart);
            if(getPricingForCart.data.length !== 0){
                setShowCartDetails(true);
                setShowEmptyCart(false);
            }
            else{
                setShowCartDetails(false);
                setShowEmptyCart(true);
            }
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        if(cartProducts.length != 0){
            calculateCartPricing();
        }
        else{
            cartPricing.base_price = 0;
            cartPricing.discount = 0;
            cartPricing.discounted_price = 0;
            cartPricing.tax = 0;
            cartPricing.delivery_charges = 0;
            cartPricing.final_price = 0;
        }
    }, [cartProducts]);

    const calculateCartPricing = async () => {
        let tempCartPricing = {
            base_price: 0,
            discount: 0,
            discounted_price: 0,
            tax: 0,
            delivery_charges: 0,
            final_price: 0,
            tax_percentage: 5
        }

        await Promise.all(cartProducts.map((product) => {
            tempCartPricing.base_price = tempCartPricing.base_price + (product.price * product.quantity);
        }));

        tempCartPricing.discount = cart.coupon_savings;
        tempCartPricing.discounted_price = tempCartPricing.base_price - tempCartPricing.discount;
        tempCartPricing.tax = tempCartPricing.discounted_price * tempCartPricing.tax_percentage / 100;
        tempCartPricing.delivery_charges = user.is_logged_in === false ? 0 : 0;
        tempCartPricing.final_price = tempCartPricing.discounted_price + tempCartPricing.tax + tempCartPricing.delivery_charges;

        setCartPricing({
            base_price: tempCartPricing.base_price,
            discount: tempCartPricing.discount,
            discounted_price: tempCartPricing.discounted_price,
            tax: tempCartPricing.tax,
            tax_percentage: tempCartPricing.tax_percentage,
            delivery_charges: tempCartPricing.delivery_charges,
            final_price: tempCartPricing.final_price
        });
    }

    const subtractThisProductQuantity = async (prod_id) => {
        let tempCartProducts = [...cartProducts];
        let index = tempCartProducts.findIndex(prod => prod.id === prod_id);

        if(tempCartProducts[index].quantity != 1){
            tempCartProducts[index].quantity -= 1;
            setCartProducts(tempCartProducts);

            dispatch(decreaseProductQty(prod_id));
        }
    }

    const addThisProductQuantity = async (prod_id) => {
        let tempCartProducts = [...cartProducts];
        
        let index = tempCartProducts.findIndex(prod => prod.id === prod_id);

        console.log(index);

        tempCartProducts[index].quantity += 1;

        setCartProducts(tempCartProducts);

        dispatch(increaseProductQty(prod_id));
    }

    const removeThisProductFromCart = async (prod_id) => {
        let tempCartProducts = [...cartProducts];

        let index = tempCartProducts.findIndex(prod => prod.id === prod_id);

        tempCartProducts.splice(index, 1);

        setCartProducts(tempCartProducts);

        dispatch(removeCartProduct(prod_id))
    }

    const navigateToCheckout = async () => {
        navigate("/checkout")
    }

    return (
        <>
            <Header />
            <Sitemap pageName="cart" pagePath="cart" />

            <div className="cartPage">
                <div className="cartPageInner">
                    <div className="cartPageHeadings">
                        <div className="cartPageSectionHeading">
                            <p className="cartPageSectionHeadingTxt">cart</p>
                        </div>
                        <div className="cartPageSectionName">
                            <p className="cartPageSectionNameTxt">one step away to place your order!</p>
                        </div>
                    </div>

                    <div className="cartPageDetails">
                        <div className="cartPageDetailsInner">
                            {
                                showCartDetails === true
                                ?
                                <div className="cartContainer">
                                    <div className="cartContainerProducts">
                                        {
                                            cartProducts.map((product, i) => {
                                                console.log(product);
                                                return (
                                                    <div className="cartContainerProductSingle" key={i+1}>
                                                        <div className="cartContainerProductSingleCover">
                                                            <div className="cartContainerProductSingleCoverImage">
                                                                <img src={product?.small_image?.url} className="cartContainerProductSingleCoverImg" />
                                                            </div>
                                                        </div>
                                                        <div className="cartContainerProductSingleNamePriceAction">
                                                            <div className="cartContainerProductSingleNamePrice">
                                                                <div className="cartContainerProductSingleName">
                                                                    <p className="cartContainerProductSingleNameTxt">
                                                                        <span className="cartContainerProductSingleNameMainTxt">{product.product?.name} </span>
                                                                        {
                                                                            product.size != undefined || product.size != null
                                                                            ?
                                                                                <span className='cartContainerProductSingleNameSizeTxt'>{product.size?.name}</span>
                                                                            :
                                                                            <></>
                                                                        }    
                                                                    </p>
                                                                </div>
                                                                <div className="cartContainerProductSinglePriceCategory">
                                                                    <div className="cartContainerProductSingleCategory">
                                                                        <p className="cartContainerProductSingleCategoryTxt">{product.category?.name}</p>
                                                                    </div>
                                                                    <div className="cartContainerProductSinglePriceCategorySeperator">
                                                                        <span className="cartContainerProductSinglePriceCategorySeperatorTxt">&nbsp;</span>
                                                                    </div>
                                                                    <div className="cartContainerProductSingleQuantity">
                                                                        <p className="cartContainerProductSingleQuantityTxt">{product.quantity}</p>
                                                                    </div>
                                                                    <div className="cartContainerProductSingleQuantitySeperator">
                                                                        <p className="cartContainerProductSingleQuantitySeperatorTxt">x</p>
                                                                    </div>
                                                                    <div className="cartContainerProductSinglePrice">
                                                                        <div className="cartContainerProductSinglePriceInner">
                                                                            <div className="cartContainerProductSinglePriceIcon">
                                                                                <i className="fas fa-rupee-sign cartContainerProductSinglePriceIco"></i>
                                                                            </div>
                                                                            <div className="cartContainerProductSinglePriceValue">
                                                                                <p className="cartContainerProductSinglePriceValueTxt">{product.price}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cartContainerProductSingleActions">
                                                                <div className="cartContainerProductSingleActionQty">
                                                                    <div className="cartContainerProductSingleActionsQtyMain">
                                                                        <div className="cartContainerProductSingleActionsQtyReduce" onClick={() => subtractThisProductQuantity(product.id)}>
                                                                            <i className="fas fa-minus cartContainerProductSingleActionsQtyReduceIco"></i>
                                                                        </div>

                                                                        <div className="cartContainerProductSingleActionsQtyLable">
                                                                            <div className="cartContainerProductSingleActionsQtyLableInner">
                                                                                <p className="cartContainerProductSingleActionsQtyLableTxt">{product.quantity}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="cartContainerProductSingleActionsQtyIncrease" onClick={() => addThisProductQuantity(product.id)}>
                                                                            <i className="fas fa-plus cartContainerProductSingleActionsQtyIncreaseIco"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="cartContainerProductSingleRemove" onClick={() => removeThisProductFromCart(product.id)}>
                                                                    <div className="cartContainerProductSingleRemoveIcon">
                                                                        <i className="fas fa-trash-alt cartContainerProductSingleRemoveIco"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="cartContainerStats">
                                        <div className="cartContainerStatsInner">
                                            <div className="cartContainerStatsBasic">
                                                <div className="cartContainerStatBasePrice">
                                                    <div className="cartContainerStatBasePriceLable">
                                                        <p className="cartContainerStatBasePriceLableTxt">total</p>
                                                    </div>
                                                    <div className="cartContainerStatBasePriceValue">
                                                        <div className="cartContainerStatBasePriceValueIcon">
                                                            <i className="fas fa-rupee-sign cartContainerStatBasePriceValueIco"></i>
                                                        </div>
                                                        <div className="cartContainerStatBasePriceValueText">
                                                            <p className="cartContainerStatBasePriceValueTxt">{cartPricing.discounted_price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cartContainerStatRegular">
                                                    <div className="cartContainerStatRegularLable">
                                                        <p className="cartContainerStatRegularLableTxt">discount</p>
                                                    </div>
                                                    <div className="cartContainerStatRegularValue">
                                                        <div className="cartContainerStatRegularValueIcon">
                                                            <i className="fas fa-rupee-sign cartContainerStatRegularValueIco"></i>
                                                        </div>
                                                        <div className="cartContainerStatRegularValueText">
                                                            <p className="cartContainerStatRegularValueTxt">{cartPricing.discount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cartContainerStatRegular">
                                                    <div className="cartContainerStatRegularLable">
                                                        <p className="cartContainerStatRegularLableTxt">tax &amp; charges</p>
                                                    </div>
                                                    <div className="cartContainerStatRegularValue">
                                                        <div className="cartContainerStatRegularValueIcon">
                                                            <i className="fas fa-rupee-sign cartContainerStatRegularValueIco"></i>
                                                        </div>
                                                        <div className="cartContainerStatRegularValueText">
                                                            <p className="cartContainerStatRegularValueTxt">{cartPricing.tax}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="cartContainerStatRegular">
                                                    <div className="cartContainerStatRegularLable">
                                                        <p className="cartContainerStatRegularLableTxt">delivery charges</p>
                                                    </div>
                                                    <div className="cartContainerStatRegularValue">
                                                        <div className="cartContainerStatRegularValueIcon">
                                                            <i className="fas fa-rupee-sign cartContainerStatRegularValueIco"></i>
                                                        </div>
                                                        <div className="cartContainerStatRegularValueText">
                                                            <p className="cartContainerStatRegularValueTxt">{cartPricing.delivery_charges}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cartContainerStatRegular deliveryChargesNote">
                                                    <p className="cartContainerStatRegularNoteTxt">Delivery charges applicable for orders below {cartPricing.min_order_value_for_free_delivery}rs.</p>
                                                </div>
                                            </div>
                                            
                                            <div className="cartContainerStatsFinal">
                                                <div className="cartContainerStatFinalPrice">
                                                    <div className="cartContainerStatFinalPriceLable">
                                                        <p className="cartContainerStatFinalPriceLableTxt">grand total</p>
                                                    </div>
                                                    <div className="cartContainerStatFinalPriceValue">
                                                        <div className="cartContainerStatFinalPriceValueIcon">
                                                            <i className="fas fa-rupee-sign cartContainerStatFinalPriceValueIco"></i>
                                                        </div>
                                                        <div className="cartContainerStatFinalPriceValueText">
                                                            <p className="cartContainerStatFinalPriceValueTxt">{cartPricing.final_price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cartContainerAction">
                                        <div className="cartContainerActionInner">
                                            <p className="cartContainerActionButton" onClick={navigateToCheckout}>proceed to checkout</p>
                                            <div className="cartContainerActionEmpty">
                                                <p className="cartContainerActionEmptyTxt" onClick={clearCart}>empty cart</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }

                            {
                                showEmptyCart === true
                                ?
                                    <div className="emptyCart">
                                        <div className="emptyCartInner">
                                            <div className="emptyCartVisual">
                                                <div className="emptyCartVisualImage">
                                                    <img src="images/empty-cart-visual.png" className="emptyCartVisualImg" />
                                                </div>
                                            </div>
                                            <div className="emptyCartContent">
                                                <div className="emptyCartContentHeading">
                                                    <p className="emptyCartContentHeadingTxt">Your cart is empty!</p>
                                                </div>
                                                <div className="emptyCartContentDescription">
                                                    <p className="emptyCartContentDescriptionTxt">Please choose your favorite items from our delicious yet healthy menu to place an order.</p>
                                                </div>

                                                <div className="emptyCartActions">
                                                    <div className="emptyCartActionsInner">
                                                        <div className="emptyCartActionSingle">
                                                            <Link to="/menu">
                                                                <p className="emptyCartActionSingleTxt">visit our menu</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <></>
                            }

                            
                        </div>
                    </div>
                </div>
            </div>

            <SubscribeNewsletter />
            <Footer />
        </>
    )
}

export default CartPage
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL } from "../config";

const PromoCodesListPopUp = ({closePromoPopUp}) => {
    const user = useSelector((state) => state.user.value);

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const [showPromoCodes, setShowPromoCodes] = useState(false);
    const [showNoPromoCode, setShowNoPromoCode] = useState(false);
    const [showLoadingPromoCode, setShowLoadingPromoCode] = useState(false);

    const [promoCodes, setPromoCodes] = useState([]);

    const getPromoCodesHandler = async () => {
        try{
            const getPromoCodesReq = await axios.get(`${API_BASE_URL}coupon/public`);
            if(getPromoCodesReq.status === 200){
                if(getPromoCodesReq.data.length != 0){
                    setPromoCodes(getPromoCodesReq.data);
                    setShowNoPromoCode(false);
                    setShowPromoCodes(true);
                }
                else{
                    setPromoCodes(getPromoCodesReq.data);
                    setShowPromoCodes(false);
                    setShowNoPromoCode(true);
                }
            }

            console.log(getPromoCodesReq.data);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getPromoCodesHandler();
    }, []);

    return (
        <div className="promoCodePopUpOverlay">
            <div className="promoCodePopUp">
                <div className="promoCodePopUpClose">
                    <div className="promoCodePopUpCloseButton" onClick={()=>{closePromoPopUp(true)}}>
                        <i className="fas fa-times-circle promoCodePopUpCloseButtonIco"></i>
                    </div>
                </div>
                <div className="promoCodePopUpInner">
                    <div className="promoCodePopUpHeading">
                        <p className="promoCodePopUpHeadingTxt">promo codes</p>
                    </div>

                    <div className="promoCodePopUpContent">
                        <div className="promoCodePopUpContentInner">
                            {
                                promoCodes.map(promoCode => {
                                    return (
                                        <div className="promoCodePopUpContentSingle" key={promoCode.id}>
                                            <div className="promoCodePopUpContentSingleHeading">
                                                <p className="promoCodePopUpContentSingleHeadingTxt">{promoCode.code}</p>
                                            </div>
                                            <div className="promoCodePopUpContentSingleSubHeading">
                                                <p className="promoCodePopUpContentSingleSubHeadingTxt">
                                                    <span className="lable">Coupon Type:</span> {promoCode.coupon_type === "FREEBIE" ? `Freebie (${promoCode.freebie_product.name})` : promoCode.coupon_type === "BUYGET" ? `BUY ${promoCode.buy_quantity} GET ${promoCode.get_quantity}` : promoCode.coupon_type === "PROMO" ? `PROMO (Upto ${promoCode.value}% OFF)` : promoCode.coupon_type === "FLAT" ? `FLAT ${promoCode.value_type === "PERCENTAGE" ? `${promoCode.value}% Off` : `${promoCode.value}Rs Off`} (${promoCode.applicable_products.length === 0 ? "On Total Order" : "On Selected Products"})` : "" }
                                                </p>
                                            </div>
                                            <div className="promoCodePopUpContentSingleStep">
                                                <div className="promoCodePopUpContentSingleHalf">
                                                    <div className="promoCodePopUpContentSingleHalfLable">
                                                        <p className="promoCodePopUpContentSingleHalfLableTxt">Applicable To:</p>
                                                    </div>
                                                    <div className="promoCodePopUpContentSingleHalfValue">
                                                        <p className="promoCodePopUpContentSingleHalfValueTxt">{promoCode.user_type === "ALL" ? "All Users" : "New Users"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                promoCode.coupon_type !== "FLAT" && promoCode.coupon_type !== "BUYGET"
                                                ?
                                                <div className="promoCodePopUpContentSingleStep">
                                                    {
                                                        promoCode.min_order_value !== 0
                                                        ?
                                                        <div className="promoCodePopUpContentSingleHalf">
                                                            <div className="promoCodePopUpContentSingleHalfLable">
                                                                <p className="promoCodePopUpContentSingleHalfLableTxt">Min. Order Value:</p>
                                                            </div>
                                                            <div className="promoCodePopUpContentSingleHalfValue">
                                                                <p className="promoCodePopUpContentSingleHalfValueTxt">{promoCode.min_order_value}Rs.</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                    
                                                    {
                                                        promoCode.max_discount !== 0
                                                        ?
                                                        <div className="promoCodePopUpContentSingleHalf">
                                                            <div className="promoCodePopUpContentSingleHalfLable">
                                                                <p className="promoCodePopUpContentSingleHalfLableTxt">Max Discount:</p>
                                                            </div>
                                                            <div className="promoCodePopUpContentSingleHalfValue">
                                                                <p className="promoCodePopUpContentSingleHalfValueTxt">{promoCode.max_discount}Rs.</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                                :
                                                <></>
                                            }
                                            
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromoCodesListPopUp
import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import storage from 'redux-persist/lib/storage';

import { combineReducers } from "redux"; 
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { BrowserRouter, useLocation  } from 'react-router-dom'; 

import userReducer from './reducers/user';
import cartReducer from './reducers/cart';

const reducers = combineReducers({
  user: userReducer,
  cart: cartReducer
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

let persistor = persistStore(store);

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Wrapper>
            <App />
          </Wrapper>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
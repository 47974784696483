import axios from "axios";
import { FB_API_VERSION, FB_PIXEL_ID, CONVERSION_API_TOKEN } from "../config";

async function hash(string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
}

export const callViewContentAPI = async (data) => {
    const name = await hash(data.name);
    const phone_number = await hash(data.phone_number);

    const api_data = {
        data: [
            {
                event_name: "ViewContent",
                event_time: new Date().toISOString(),
                action_source: "website",
                user_data: {
                    fn: [
                        name
                    ],
                    ph: [
                        phone_number
                    ]
                },
                custom_data: {
                    currency: "INR",
                    value: data.price
                }
            }
        ]
    }

    const conversionApiReq = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${CONVERSION_API_TOKEN}`, api_data);
    console.log(conversionApiReq);
    return true
}

export const callAddToCarttAPI = async (data) => {
    const name = await hash(data.name);
    const phone_number = await hash(data.phone_number);

    const api_data = {
        data: [
            {
                event_name: "AddToCart",
                event_time: new Date().toISOString(),
                action_source: "website",
                user_data: {
                    fn: [
                        name
                    ],
                    ph: [
                        phone_number
                    ]
                },
                contents: [
                    {
                      id: data.product_name,
                      quantity: data.quantity
                    }
                ],
                custom_data: {
                    currency: "INR",
                    value: data.price
                }
            }
        ]
    }

    const conversionApiReq = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${CONVERSION_API_TOKEN}`, api_data);
    console.log(conversionApiReq);
    return true
}

export const callInitiateCheckoutAPI = async (data) => {
    const name = await hash(data.name);
    const phone_number = await hash(data.phone_number);

    const api_data = {
        data: [
            {
                event_name: "InitiateCheckout",
                event_time: new Date().toISOString(),
                action_source: "website",
                user_data: {
                    fn: [
                        name
                    ],
                    ph: [
                        phone_number
                    ]
                },
                custom_data: {
                    currency: "INR",
                    value: data.price
                }
            }
        ]
    }

    const conversionApiReq = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${CONVERSION_API_TOKEN}`, api_data);
    console.log(conversionApiReq);
    return true
}

export const callCompleteRegistrationAPI = async (data) => {
    const name = await hash(data.name);
    const phone_number = await hash(data.phone_number);

    const api_data = {
        data: [
            {
                event_name: "CompleteRegistration",
                event_time: new Date().toISOString(),
                action_source: "website",
                user_data: {
                    fn: [
                        name
                    ],
                    ph: [
                        phone_number
                    ]
                },
                custom_data: {
                    currency: "INR",
                    value: data.price
                }
            }
        ]
    }

    const conversionApiReq = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${CONVERSION_API_TOKEN}`, api_data);
    console.log(conversionApiReq);
    return true
}

export const callFindLocationAPI = async (data) => {
    const name = await hash(data.name);
    const phone_number = await hash(data.phone_number);

    const api_data = {
        data: [
            {
                event_name: "FindLocation",
                event_time: new Date().toISOString(),
                action_source: "website",
                user_data: {
                    fn: [
                        name
                    ],
                    ph: [
                        phone_number
                    ]
                },
                custom_data: {
                    currency: "INR",
                    value: data.price
                }
            }
        ]
    }

    const conversionApiReq = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${CONVERSION_API_TOKEN}`, api_data);
    console.log(conversionApiReq);
    return true
}

export const callPurchaseAPI = async (data) => {
    const name = await hash(data.name);
    const phone_number = await hash(data.phone_number);

    const api_data = {
        data: [
            {
                event_name: "Purchase",
                event_time: new Date().toISOString(),
                action_source: "website",
                user_data: {
                    fn: [
                        name
                    ],
                    ph: [
                        phone_number
                    ]
                },
                contents: data.products,
                custom_data: {
                    currency: "INR",
                    value: data.price
                }
            }
        ]
    }

    const conversionApiReq = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${CONVERSION_API_TOKEN}`, api_data);
    console.log(conversionApiReq);
    return true
}

export const callSubscribeAPI = async () => {

    const api_data = {
        data: [
            {
                event_name: "Subscribe",
                event_time: new Date().toISOString(),
                action_source: "website",
            }
        ]
    }

    const conversionApiReq = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${CONVERSION_API_TOKEN}`, api_data);
    console.log(conversionApiReq);
    return true
}

export const callContactAPI = async () => {

    const api_data = {
        data: [
            {
                event_name: "Contact",
                event_time: new Date().toISOString(),
                action_source: "website",
            }
        ]
    }

    const conversionApiReq = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${CONVERSION_API_TOKEN}`, api_data);
    console.log(conversionApiReq);
    return true
}
import React, {useState, useEffect} from 'react';
import axios from "axios";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, GOOGLE_MAPS_API_KEY } from "../config";

import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { login } from '../reducers/user';
import { callFindLocationAPI } from '../utils/conversionApiEvents';

const AddDeliveryLocation = ({getAddDeliveryLocationStatus, fromCheckout}) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.value);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY
    });

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);

    const [userLatitude, setUserLatitude] = useState('');
    const [userLongitude, setUserLongitude] = useState('');
    const [userLatlong, setUserLatlong] = useState('');

    const [showNameError, setShowNameError] = useState(false);
    const [nameErrorTxt, setNameErrorTxt] = useState('');

    const [showAddressError, setShowAddressError] = useState(false);
    const [addressErrorTxt, setAddressErrorTxt] = useState('');

    const [showAddressInputs, setShowAddressInputs] = useState(true);
    const [showUserLocationMaps, setShowUserLocationMaps] = useState(false);

    const [stores, setStores] = useState([]);

    const [gmap, setGmap] = useState(null);

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const getStores = async () => {
        const getStoreReq = await axios.get(`${API_BASE_URL}store?status=true`);
        if(getStoreReq.status === 200){
            setStores(getStoreReq.data.items);
        }
    }

    useEffect(() => {
        getStores();
    }, []);

    const getUserDistance = async (store, user) => {

        let distance;
        let duration;

        const directionsService = new window.google.maps.DirectionsService();
        const distanceData = await directionsService.route({origin: store, destination: user, travelMode: 'DRIVING'});

        if(distanceData.status === 'OK'){
            const dData = distanceData.routes[0].legs[0];

            distance = Math.floor(dData.distance.value / 1000);
            duration = Math.floor(dData.duration.value / 60);
        }

        return {
            distance: distance,
            duration: duration
        };
    } 

    const findNearestStore = async (stores, location) => {

        const nearestStore = [];
        let nearestLocation = null;

        for(let i = 0;i < stores.length;i++){
            let storeLocation = `${stores[i].latlong.lat}, ${stores[i].latlong.lng}`;
            let userLocation = location;
            let distanceReq = await getUserDistance(storeLocation, userLocation);
    
            if(nearestLocation === null){
                nearestStore.push(stores[i]);
                nearestLocation = distanceReq.distance;
            }
            else{
                if(distanceReq.distance < nearestLocation){
                    nearestStore = [];
                    nearestStore.push(stores[i]);
                    nearestLocation = distanceReq.distance;
                }
            }
        }

        return{
            store: nearestStore[0],
            storeDistance: nearestLocation
        }
    }

    const updateLatLng = async (data) => {
        setUserLatitude(data.lat);
        setUserLongitude(data.lng);

        setUserLatlong({
            lat: data.lat,
            lng: data.lng
        });

        let userLocation = `${data.lat}, ${data.lng}`;

        let nearestStore = await findNearestStore(stores, userLocation);

        if(nearestStore.storeDistance > 10){
            setShowAddressError(true);
            setAddressErrorTxt('We do not serve at this location yet');
        }
        else {
            setShowAddressError(false);
            setShowUserLocationMaps(true);
        }
    }

    const viewLocationOnMap = async () => {
        if (name === '') {
            setShowNameError(true);
            setNameErrorTxt('Your Location Name is Required.');
        }
        else{
            setShowNameError(false);
            setNameErrorTxt('');
        }

        if (address === '') {
            setShowAddressError(true);
            setAddressErrorTxt('Your Address is Required.');
            return;
        }
        else{
            setShowAddressError(false);
            setAddressErrorTxt('');
        }

        Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("IN");
        Geocode.enableDebug();

        var userLat;
        var userLong;

        const geoLocation = await Geocode.fromAddress(address);

        const { lat, lng } = geoLocation.results[0].geometry.location;

        userLat = lat;
        userLong = lng;

        setUserLatlong({
            lat: userLat,
            lng: userLong
        });

        setUserLatitude(userLat);
        setUserLongitude(userLong);

        let userLocation = `${lat}, ${lng}`;

        let nearestStore = await findNearestStore(stores, userLocation);

        if(nearestStore.storeDistance > 10){
            setShowAddressError(true);
            setAddressErrorTxt('We do not serve at this location yet');
        }
        else{
            setShowUserLocationMaps(true);
            setIsAddressConfirmed(true);
            setShowAddressInputs(false);
        }
    }

    const addThisUserLocation = async () => {
        const data = {
            id: user.id,
            name: name,
            address: address,
            latLng: {
                lat: userLatitude,
                lng: userLongitude
            }
        }

        try{
            const locationAddReq = await axios.post(`${API_BASE_URL}customer/locations/add`, data, config);
            if (locationAddReq.status === 200) {
                const result_data = {
                    status: true,
                    user_id: locationAddReq.data.user
                }

                getUser();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const getUser = async () => {
        const getUserReq = await axios.get(`${API_BASE_URL}customer/${user.id}`, config);
        if(getUserReq.status === 200){
            
            let access_token = user.access_token;
            let refresh_token = user.refresh_token;

            if(getUserReq.status === 200){
                const userData = {
                    id: getUserReq.data.id,
                    name: getUserReq.data.name,
                    is_phone_number_validated: true,
                    phone_number: getUserReq.data.phone_number,
                    access_token: access_token,
                    refresh_token: refresh_token,
                    orders: getUserReq.data.delivered_orders,
                    locations: getUserReq.data.locations,
                    is_active: getUserReq.data.is_active,
                    is_logged_in: true
                }
                dispatch(login(userData));
                getAddDeliveryLocationStatus({
                    status: true
                });
            }
        }
    }

    const mapLocationUpdated = async (e) => {
        const data = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        }
        updateLatLng(data);
    }

    const changeThisUserAddress = () => {
        setShowUserLocationMaps(false);
        setShowAddressInputs(true);
    }

    const cancelAddNewLocation = () => {
        getAddDeliveryLocationStatus({
            status: true
        });
    }
    
    return (
        <div className="deliveryLocations">
            {
                fromCheckout === true
                ?
                <div className="deliveryLocationsCancel" onClick={cancelAddNewLocation}>
                    <div className="deliveryLocationsCancelInner">
                        <div className="deliveryLocationsCancelIcon">
                            <i className="fas fa-times-circle deliveryLocationsCancelIco"></i>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            <div className="deliveryLocationsHeading">
                <p className="deliveryLocationsHeadingTxt">add location</p>
            </div>
            <div className="deliveryLocationForm">
                {
                    showAddressInputs === true
                    ?
                    <>
                        <div className="deliveryLocationFormStep">
                            <div className="deliveryLocationFormStepLable">
                                <p className="deliveryLocationFormStepLableTxt">name</p>
                            </div>
                            <div className="deliveryLocationFormStepInput">
                                <input type="text" className="deliveryLocationFormStepInputTxt" placeholder="enter name. (eg: home)" onChange={(e) => {setName(e.target.value)}}  />
                                {
                                    showNameError === true
                                    ?
                                    <p className="formErrorTxt">{nameErrorTxt}</p>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="deliveryLocationFormStep">
                            <div className="deliveryLocationFormStepLable">
                                <p className="deliveryLocationFormStepLableTxt">full address</p>
                            </div>
                            <div className="deliveryLocationFormStepInput">
                                <input type="text" className="deliveryLocationFormStepInputTxt" placeholder="enter your full address" onChange={(e) => {setAddress(e.target.value)}}  />
                                {
                                    showAddressError === true
                                    ?
                                    <p className="formErrorTxt">{addressErrorTxt}</p>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="deliveryLocationFormStep">
                            <button className="deliveryLocationFormStepInputButton" onClick={viewLocationOnMap}>View Location on Map</button>
                        </div>
                    </>
                    :
                    <></>
                }
                
                {
                    showUserLocationMaps === true
                        ?
                        <>
                            <div className="deliveryLocationFormStep">
                                <GoogleMap
                                    mapContainerStyle={{'width': '100%', 'height': '400px'}}
                                    center={userLatlong}
                                    zoom={16}
                                    options={{
                                        streetViewControl: false,
                                        mapTypeControl: false,
                                        fullscreenControl: false
                                    }}
                                    onLoad={map => setGmap(map)}
                                >
                                    <MarkerF position={userLatlong} draggable={true} onDragEnd={(e) => mapLocationUpdated(e)} />
                                </GoogleMap>
                            </div>
                            {
                                showAddressError === true
                                ?
                                <div className="deliveryLocationFormStep">
                                    <p className="formErrorTxt" style={{marginTop: 0}}>{addressErrorTxt}</p>
                                </div>
                                :
                                <></>
                            }
                            <div className="deliveryLocationFormStep actions">
                                <button className="deliveryLocationFormStepInputButton" onClick={changeThisUserAddress}>change address</button>
                                {
                                    showAddressError === false
                                    ?
                                    <button className="deliveryLocationFormStepInputButton" onClick={addThisUserLocation}>submit</button>
                                    :
                                    <></>
                                }
                            </div>
                        </>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default AddDeliveryLocation;
import React from 'react'
import { BASE_URL } from '../config'

const HomeModes = () => {
    return (
        <div className="homeModes">
            <div className="homeModesInner">
                <div className="homeModesSubHeading" data-aos="fade-up">
                    <p className="homeModesSubHeadingTxt">modes of service</p>
                </div>
                <div className="homeModesHeading" data-aos="fade-up">
                    <p className="homeModesHeadingTxt">we serve you as you need</p>
                </div>
                <div className="homeModesList">
                    <div className="homeModeSingle" data-aos="fade-right">
                        <div className="homeModeSingleCover">
                            <img src={`${BASE_URL}images/dine-in.jpg`} className="homeModeSingleCoverImg" />
                        </div>
                        <div className="homeModeSingleContent">
                            <div className="homeModeSingleContentInner">
                                <div className="homeModeSingleContentHeading">
                                    <p className="homeModeSingleContentHeadingTxt">01. dine-in</p>
                                </div>
                                <div className="homeModeSingleContentDescription">
                                    <p className="homeModeSingleContentDescriptionTxt">Enjoy our menu hot and fresh with your friends and family at our place.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="homeModeSingle" data-aos="fade-up">
                        <div className="homeModeSingleCover">
                            <img src={`${BASE_URL}images/take-away.jpg`} className="homeModeSingleCoverImg" />
                        </div>
                        <div className="homeModeSingleContent">
                            <div className="homeModeSingleContentInner">
                                <div className="homeModeSingleContentHeading">
                                    <p className="homeModeSingleContentHeadingTxt">02. takeaway</p>
                                </div>
                                <div className="homeModeSingleContentDescription">
                                    <p className="homeModeSingleContentDescriptionTxt">Place your order online and have the order ready before your arrival. Smart!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="homeModeSingle" data-aos="fade-left">
                        <div className="homeModeSingleCover">
                            <img src={`${BASE_URL}images/home-delivery.jpg`} className="homeModeSingleCoverImg" />
                        </div>
                        <div className="homeModeSingleContent">
                            <div className="homeModeSingleContentInner">
                                <div className="homeModeSingleContentHeading">
                                    <p className="homeModeSingleContentHeadingTxt">03. home delivery</p>
                                </div>
                                <div className="homeModeSingleContentDescription">
                                    <p className="homeModeSingleContentDescriptionTxt">Unable to walk-in? Need not worry. We will deliver the order at your doorsteps.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeModes
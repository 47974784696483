import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Reviews = () => {
    return (
        <div className="reviews">
            <div className="reviewsInner">
                <div className="reviewsHeadings">
                    <div className="reviewsPageName" data-aos="fade-up">
                        <p className="reviewsPageNameTxt">reviews</p>
                    </div>
                    <div className="reviewsSectionHeading" data-aos="fade-up">
                        <p className="reviewsSectionHeadingTxt">what people say about us!</p>
                    </div>
                </div>
                <div className="reviewsContainer" data-aos="fade-up">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30
                            }
                        }}
                        className="reviewsContainerInner swiper-wrapper"
                    >
                        <SwiperSlide className="reviewSingle swiper-slide">
                            <div className="reviewSingleQuote">
                                <i className="fas fa-quote-right reviewSingleQuoteIco"></i>
                            </div>
                            <div className="reviewSingleInner">
                                <div className="reviewSingleDescription">
                                    <p className="reviewSingleDescriptionTxt">I Like the flovour and taste, it was very satisfiable with unquie toppings combination.</p>
                                </div>
                                <div className="reviewSingleUser">
                                    <div className="reviewSingleUserAvatar">
                                        <div className="reviewSingleUserAvatarImage">
                                            <img src="/images/user-default.jpg" className="reviewSingleUserAvatarImg" />
                                        </div>
                                    </div>

                                    <div className="reviewSingleUserDetails">
                                        <div className="reviewSingleUserDetailsName">
                                            <p className="reviewSingleUserDetailsNameTxt">Subash C Bose </p>
                                        </div>
                                        <div className="reviewSingleUserDetailsRating">
                                            <div className="reviewSingleUserDetailsRatingInner">
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="reviewSingle swiper-slide">
                            <div className="reviewSingleQuote">
                                <i className="fas fa-quote-right reviewSingleQuoteIco"></i>
                            </div>
                            <div className="reviewSingleInner">
                                <div className="reviewSingleDescription">
                                    <p className="reviewSingleDescriptionTxt">Taste was good, seems to be unquie to me never tried these kind of pizza before, I loved it.</p>
                                </div>
                                <div className="reviewSingleUser">
                                    <div className="reviewSingleUserAvatar">
                                        <div className="reviewSingleUserAvatarImage">
                                            <img src="/images/user-default.jpg" className="reviewSingleUserAvatarImg" />
                                        </div>
                                    </div>

                                    <div className="reviewSingleUserDetails">
                                        <div className="reviewSingleUserDetailsName">
                                            <p className="reviewSingleUserDetailsNameTxt">Meghana Dattaraj</p>
                                        </div>
                                        <div className="reviewSingleUserDetailsRating">
                                            <div className="reviewSingleUserDetailsRatingInner">
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star-half-alt reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="reviewSingle swiper-slide">
                            <div className="reviewSingleQuote">
                                <i className="fas fa-quote-right reviewSingleQuoteIco"></i>
                            </div>
                            <div className="reviewSingleInner">
                                <div className="reviewSingleDescription">
                                    <p className="reviewSingleDescriptionTxt">Very nice pizza, never tasted this kind of pizza toppings was very good loved it but should be little spicy I guess.</p>
                                </div>
                                <div className="reviewSingleUser">
                                    <div className="reviewSingleUserAvatar">
                                        <div className="reviewSingleUserAvatarImage">
                                            <img src="/images/user-default.jpg" className="reviewSingleUserAvatarImg" />
                                        </div>
                                    </div>

                                    <div className="reviewSingleUserDetails">
                                        <div className="reviewSingleUserDetailsName">
                                            <p className="reviewSingleUserDetailsNameTxt">Kanya Kumari</p>
                                        </div>
                                        <div className="reviewSingleUserDetailsRating">
                                            <div className="reviewSingleUserDetailsRatingInner">
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                                <div className="reviewSingleUserDetailsRatingStarSingle">
                                                    <i className="fas fa-star-half-alt reviewSingleUserDetailsRatingStarSingleIco"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="reviewsContainerPagination"></div>
                </div>
            </div>
        </div>
    )
}

export default Reviews
import './styles/globals.scss';
import './styles/all.min.css';
import './styles/icofont.min.css';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './AppRouter';

function App() {
  return <AppRouter />
}

export default App;

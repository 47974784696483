/**
 * DEV DEPENDENCIES
 */

//  export const API_BASE_URL = "http://localhost:4041/";
//  export const BASE_URL = "/";
//  export const SOCKET_URL = "http://localhost:4041";
//  export const BASE_LINK = "/";
//  export const SOCKET_PATH = "/";
//  export const GOOGLE_MAPS_API_KEY = "AIzaSyAEa1uvbs1m8u4OsFnEIqVwY2Ho3vDJhWY";
//  export const DELIVERY_PRICE_PER_KM = 12;
//  export const MIN_ORDER_VALUE_FOR_FREE_DELIVERY = 299;
//  export const FREE_DELIVERY_UPTO_KM = 3;
//  export const FB_API_VERSION = "v15.0";
//  export const FB_PIXEL_ID = "639626683765678";
//  export const CONVERSION_API_TOKEN = "EAAMAklnhhuoBAEPHCRn4sV8kdjr4SFLZAKsX7q9UI4SuuJfc1jZB5pjCbZCzxDU070Q9xNkElJQnjIzVuQB9ZAqf9xYIAbzbN0Q7Sv74T4AtlbGzvJzYZBn02E71fphFZCP0gno867v8apCjYBAfxbQ1vZAgZCkPZCKEO7uvrpFL5j5sdVCoksuzAQetSqsbTMIwZD";
 

 /**
  * PROD DEPENDENCIES
  */
 
 export const API_BASE_URL = "https://api.blackbox.pizza/";
 export const BASE_LINK = "/";
 export const BASE_URL = "/";
 export const SOCKET_URL = "https://api.blackbox.pizza";
 export const SOCKET_PATH = "/socket.io";
 export const GOOGLE_MAPS_API_KEY = "AIzaSyAEa1uvbs1m8u4OsFnEIqVwY2Ho3vDJhWY";
 export const DELIVERY_PRICE_PER_KM = 12;
 export const MIN_ORDER_VALUE_FOR_FREE_DELIVERY = 299;
 export const FREE_DELIVERY_UPTO_KM = 3;
 export const FB_API_VERSION = "v15.0";
 export const FB_PIXEL_ID = "639626683765678";
 export const CONVERSION_API_TOKEN = "EAAMAklnhhuoBAEPHCRn4sV8kdjr4SFLZAKsX7q9UI4SuuJfc1jZB5pjCbZCzxDU070Q9xNkElJQnjIzVuQB9ZAqf9xYIAbzbN0Q7Sv74T4AtlbGzvJzYZBn02E71fphFZCP0gno867v8apCjYBAfxbQ1vZAgZCkPZCKEO7uvrpFL5j5sdVCoksuzAQetSqsbTMIwZD";
 

 /**
  * COMMON FOR BOTH DEV & PROD
  */
 export const BRAND_NAME = "Blackbox Pizza";
 export const JWT_SECRET = "boxLive";
 export const FIRST_PAGE = 1;
 export const DEFAULT_PAGE_SIZE = 10;
 export const LOGGER = process.env.NODE_ENV === 'development';
 export const ROLES = {
        SUPER_ADMIN: 'SUPER_ADMIN',
        ADMIN: 'ADMIN',
        STORE_ADMIN:'STORE_ADMIN'
    }
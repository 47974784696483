import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AOS from 'aos';
import Header from '../components/Header';
import HomeAbout from '../components/HomeAbout';
import HomeBanner from '../components/HomeBanner';
import HomeModes from '../components/HomeModes';
import SubscribeNewsletter from '../components/SubscribeNewsletter';
import Footer from '../components/Footer';

import io from 'socket.io-client';
import { SOCKET_PATH, SOCKET_URL } from '../config';
import HomeProductsByCategory from '../components/HomeProductsByCategory';
import HomeRecommendedProducts from '../components/HomeRecommendedProducts';
// import { callViewContentAPI } from '../utils/conversionApiEvents';

// const socket = io(SOCKET_URL, {path: SOCKET_PATH, 'transports': ['websocket', 'polling']});

function HomePage() {

  const user = useSelector((state) => state.user.value);

  useEffect(() => {
    AOS.init({
      duration : 1000
    });

    // sendAdminBasicMessage();

    // const data = {
    //   name: user.name,
    //   phone_number: user.phone_number,
    //   price: 0
    // }

    // callViewContentAPI(data);
  }, []);
  
  // const sendAdminBasicMessage = () => {
  //   socket.emit("join_room", "admin");
  // }

  return (
    <>
      <Header />
      {/* <HomeBanner /> */}
      <HomeAbout />
      <HomeProductsByCategory />
      <HomeModes />
      <HomeRecommendedProducts />
      <SubscribeNewsletter />
      <Footer />
    </>
  )
}

export default HomePage
import { createSlice } from "@reduxjs/toolkit";

const initialValue = {id: '', name: '', is_phone_number_validated: false, phone_number: '', access_token: '', refresh_token: '', otp: '', orders: [], locations: [], is_active: false, is_logged_in: false};

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: initialValue
    },
    reducers: {
        login: (state, action) => {
            state.value = action.payload;
        },
        logout: (state) => {
            state.value = initialValue;
        }
    }
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
import React from 'react';
import { BASE_URL } from '../config';

const Sitemap = ({pageName, pagePath}) => {
    return (
        <div className="sitemap">
            <div className="sitemapInner">
                <div className="sitemapContent">
                    <div className="sitemapContentPageName">
                        <p className="sitemapContentPageNameTxt">{pageName}</p>
                    </div>
                    <div className="sitemapContentPageNav">
                        <div className="sitemapContentPageNavInner">
                            <div className="sitemapContentPageNavRoot">
                                <p className="sitemapContentPageNavRootTxt">home</p>
                            </div>
                            <div className="sitemapContentPageNavSeperator">
                                <p className="sitemapContentPageNavSeperatorTxt">&nbsp;</p>
                            </div>
                            <div className="sitemapContentPageNavCurrent">
                                <p className="sitemapContentPageNavCurrentTxt">{pageName}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sitemapOverlayItems">
                <div className="sitemapOverlayItemsInner">
                    <div className="sitemapOverlayItemLeafs" data-aos="fade-up">
                        <img src={`${BASE_URL}images/sitemap-leaf-bg.png`} className="sitemapOverlayItemLeafsImg" />
                    </div>
                    <div className="sitemapOverlayItemMushroom" data-aos="fade-down">
                        <img src={`${BASE_URL}images/header-shape-3.png`} className="sitemapOverlayItemMushroomImg" />
                    </div>
                    <div className="sitemapOverlayItemTomatoLeft">
                        <img src={`${BASE_URL}images/header-shape-1.png`} className="sitemapOverlayItemTomatoImg" />
                    </div>
                    <div className="sitemapOverlayItemTomatoRight">
                        <img src={`${BASE_URL}images/header-shape-1.png`} className="sitemapOverlayItemTomatoImg" />
                    </div>
                    <div className="sitemapOverlayItemChilli">
                        <img src={`${BASE_URL}images/header-shape-2.png`} className="sitemapOverlayItemChilliImg" />
                    </div>
                    <div className="sitemapOverlayItemGarlic">
                        <img src={`${BASE_URL}images/header-shape-4.png`} className="sitemapOverlayItemGarlicImg" />
                    </div>
                </div>
                <div className="sitemapOverlayItemsOverlay">&nbsp;</div>
            </div>
        </div>
    )
}

export default Sitemap
import React from 'react';
import { Link } from "react-router-dom";
import { BASE_URL } from '../config';


export default function HomeAbout() {
    return(
        <div className="homeAbout">
            <div className="homeAboutInner">
                <div className="homeAboutContent" data-aos="fade-right">
                    <div className="homeAboutContentInner">
                        <div className="homeAboutContentSubHeading">
                            <p className="homeAboutContentSubHeadingTxt">welcome to BLACKBOX!</p>
                        </div>
                        <div className="homeAboutContentHeading">
                            <p className="homeAboutContentHeadingTxt">We Serve The Best Pizza In The Town.</p>
                        </div>
                        <div className="homeAboutContentDescription">
                            <p className="homeAboutContentDescriptionTxt">Blackbox, A thin base Pizza shop located in Nagarbhavi, Bangalore. We make delicious yet healthy Pizza, Sides, Mocktails, Desserts & much more. Visit us today or place your order online to taste the best pizza in Bangalore. We are happy to serve you.</p>
                        </div>
                        <div className="homeAboutContentAction">
                            <Link to="/menu">
                                <div className="homeAboutContentActionButton">
                                    <p className="homeAboutContentActionTxt">view our menu</p>
                                    <div className="homeAboutContentActionButtonIcon">
                                        <i className="fas fa-chevron-right homeAboutContentActionButtonIco"></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="homeAboutVisual">
                    <div className="homeAboutVisualSmall">
                        <div className="homeAboutVisualSmallSingle" data-aos="fade-down-right">
                            <div className="homeAboutVisualSmallSingleImage">
                                <img src={`${BASE_URL}images/about-us-1.jpg`} className="homeAboutVisualSmallSingleImg" />
                            </div>
                        </div>
                        <div className="homeAboutVisualSmallSingle" data-aos="fade-up-right">
                            <div className="homeAboutVisualSmallSingleImage">
                                <img src={`${BASE_URL}images/about-us-3.jpg`} className="homeAboutVisualSmallSingleImg" />
                            </div>
                        </div>
                    </div>
                    <div className="homeAboutVisualLarge">
                        <div className="homeAboutVisualLargeImage" data-aos="fade-left">
                            <img src={`${BASE_URL}images/about-us-2.jpg`} className="homeAboutVisualLargeImg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
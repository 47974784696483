import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination } from 'swiper';
import { BASE_URL } from '../config';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []); 
    return windowSize;
}

const HomeBanner = () => {
    const [isMobile, setIsMobile] = useState(false);
    const size = useWindowSize();

    useEffect(() => {
        let screen = size.width;
        if(screen <= 650){
            setIsMobile(true);
        }
        else{
            setIsMobile(false);
        }
    }, [size]);

    return(
        <>
            <div className="homeBanner">
                <Swiper
                    slidesPerView={1}
                    className="homeBannerSlides"
                    centeredSlides={true} autoplay={{
                        "delay": 4000,
                        "disableOnInteraction": false
                    }} pagination={{
                        "clickable": true
                    }}
                    effect="cube"
                    loop={false}
                >
                    {
                        isMobile === true
                        ?
                        <>
                            <SwiperSlide className="homeBannerSlide">
                                <img src={`${BASE_URL}images/new-year-portrait.jpg`} className="homeBannerSlideImg" />
                            </SwiperSlide>
                        </>
                        :
                        <>
                            <SwiperSlide className="homeBannerSlide">
                                <img src={`${BASE_URL}images/new-year-landscape.jpg`} className="homeBannerSlideImg" />
                            </SwiperSlide>
                        </>
                    }
                </Swiper>
            </div>
        </>
    )
}

export default HomeBanner;
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { isExpired, decodeToken } from "react-jwt";
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, JWT_SECRET } from "../config";
import { login } from '../reducers/user';


const LoginForm = ({getLoginStatus}) => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.value);

    const [mobileNo, setMobileNo] = useState('');
    const [otp, setOTP] = useState('');

    const [fullName, setFullName] = useState();
    const [showRegisterUser, setShowRegisterUser] = useState(false);
    const [showLoginUser, setShowLoginUser] = useState(true);
    const [showValidateOTP, setShowValidateOTP] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(user.is_logged_in);
    const [hasDeliveryLocation, setHasDeliveryLocation] = useState(user.locations?.length === 0 ? false : true);

    const isPhoneNoValid = (phone) => {
        let pattern = /^\(?([6-9]{1}[0-9]{9})$/;
        if(phone.match(pattern)){
            return true;
        }
        else{
            return false
        }
    }

    const loginFormSubmitted = (e) => {
        e.preventDefault();

        if(mobileNo){
            if(mobileNo.length === 10){
                let phoneNoValidity =  isPhoneNoValid(mobileNo);
                if(phoneNoValidity === true){
                    signIn(mobileNo);
                }
                else{
                    console.log('invalid');
                }
            }
        }
    }

    const signIn = async (phoneNo) => {
        if(showRegisterUser === true){

            const data = {
                phone_number: phoneNo,
                name: fullName
            }

            const registerReq = await axios.post(`${API_BASE_URL}customer/register/public`, data);
            if(registerReq.status === 201){
                setShowRegisterUser(false);
                setShowLoginUser(false);
                setShowValidateOTP(true);
            }
        }
        else{
            const data = {
                phone_number: phoneNo
            }
            const loginReq = await axios.post(`${API_BASE_URL}customer/login-public`, data);
            if(loginReq.status === 201){
                setShowRegisterUser(true);
            }
            else if (loginReq.status === 200){
                setShowRegisterUser(false);
                setShowLoginUser(false);
                setShowValidateOTP(true);
            }
        }
    }

    const validateOTPFormSubmitted = async (e) => {
        e.preventDefault();
        const data = {
            otp: otp,
            phone_number: mobileNo
        }
        const validateOtpReq = await axios.post(`${API_BASE_URL}customer/validateOTP`, data);
        console.log(validateOtpReq);
        if(validateOtpReq.status === 200){

            const customer_id = validateOtpReq.data.customer_id;
            const token = validateOtpReq.data.access_token;

            const myDecodedToken = await decodeToken(token, JWT_SECRET);
            const isMyTokenExpired = isExpired(token);

            if (myDecodedToken.role === 'CUSTOMER') {
                console.log('customer');
                if(isMyTokenExpired === false){
                    console.log('not expired');
                    const config = {
                        headers: {
                            'Authorization': validateOtpReq.data.access_token
                        }
                    }
                    try {
                        const customer = await axios.get(`${API_BASE_URL}customer/${validateOtpReq.data.customer_id}`, config);
                        console.log(customer);

                        if(customer.status === 200){
                            const userData = {
                                id: customer.data.id,
                                name: customer.data.name,
                                is_phone_number_validated: true,
                                phone_number: customer.data.phone_number,
                                access_token: validateOtpReq.data.access_token,
                                refresh_token: validateOtpReq.data.refresh_token,
                                orders: customer.data.delivered_orders,
                                locations: customer.data.locations,
                                is_active: customer.data.is_active,
                                is_logged_in: true
                            }

                            dispatch(login(userData));
                        }
                    }
                    catch(error) {
                        console.log(error);
                    }
                }
            }
            else{
                console.log('Invalid User Type');
            }
        }
        else{
            setIsLoggedIn(false);
            setShowLoginUser(true);
        }
    }

    useEffect(() => {
        if(user.is_logged_in === true){
            getLoginStatus({
                is_logged_in: true
            });
        }
    }, [user]);

    return (
        <div className="checkoutLogin">
            <div className="checkoutLoginInner">
                <div className="checkoutLoginHeading">
                    <p className="checkoutLoginHeadingTxt">login</p>
                </div>

                <div className="checkoutLoginForm">
                    {
                        showLoginUser === true
                        ?
                        <div className="checkoutLoginFormInner">
                            <form onSubmit={(e) => loginFormSubmitted(e)}>
                                <div className="checkoutLoginFormStep">
                                    <div className="checkoutLoginFormStepLable">
                                        <p className="checkoutLoginFormStepLableTxt">Mobile Number</p>
                                    </div>
                                    <div className="checkoutLoginFormStepInput">
                                        <input type="tel" className="checkoutLoginFormStepInputTxt" required pattern="[6-9]{1}[0-9]{9}" placeholder="enter your 10 digit mobile no"  onChange={(e) => setMobileNo(e.target.value)} value={mobileNo} />
                                    </div>
                                </div>
                                {
                                    showRegisterUser === true
                                    ?
                                    <>
                                        <div className="checkoutLoginFormStep">
                                            <div className="checkoutLoginFormStepLable">
                                                <p className="checkoutLoginFormStepLableTxt">Full Name</p>
                                            </div>
                                            <div className="checkoutLoginFormStepInput">
                                                <input type="text" className="checkoutLoginFormStepInputTxt" required placeholder="enter your full name" minLength={3}  onChange={(e) => setFullName(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="checkoutLoginFormStep">
                                            <input type="submit" className="checkoutLoginFormStepInputButton" value="submit" />
                                        </div>
                                    </>
                                    :
                                    <div className="checkoutLoginFormStep">
                                        <input type="submit" className="checkoutLoginFormStepInputButton" value="submit" />
                                    </div>
                                }
                            </form>
                        </div>
                        :
                        <>
                            {
                                showValidateOTP === true
                                ?
                                <div className="checkoutLoginFormInner">
                                    <form onSubmit={(e) => validateOTPFormSubmitted(e)}>
                                        <div className="checkoutLoginFormStep">
                                            <div className="checkoutLoginFormStepLable">
                                                <p className="checkoutLoginFormStepLableTxt">OTP</p>
                                            </div>
                                            <div className="checkoutLoginFormStepInput">
                                                <input type="tel" className="checkoutLoginFormStepInputTxt" required placeholder="enter the OTP" onChange={(e) => setOTP(e.target.value)} value={otp} />
                                            </div>
                                        </div>
                                        <div className="checkoutLoginFormStep">
                                            <input type="submit" className="checkoutLoginFormStepInputButton" value="submit" />
                                        </div>
                                    </form>
                                </div>
                                :
                                <></>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default LoginForm
import React, { useEffect } from 'react';
import AOS from 'aos';
import Footer from '../components/Footer'
import Header from '../components/Header'
import HomeAbout from '../components/HomeAbout'
import HomeModes from '../components/HomeModes'
import Reviews from '../components/Reviews'
import Sitemap from '../components/Sitemap'
import SubscribeNewsletter from '../components/SubscribeNewsletter'
import { callViewContentAPI } from '../utils/conversionApiEvents';
import { useSelector } from 'react-redux';

const AboutPage = () => {

  const user = useSelector((state) => state.user.value);

  useEffect(() => {
    AOS.init({
      duration : 1000
    });
  }, []);

  return (
    <>
      <Header />
      <Sitemap pageName="about us" pagePath="about-us" />
      <HomeAbout />
      <HomeModes />
      <Reviews />
      <SubscribeNewsletter />
      <Footer />
    </>
  )
}

export default AboutPage
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { API_BASE_URL, LOGGER } from '../config';

const KotPrint = () => {
    let { id } = useParams(); 
  
    const [order, setOrder] = useState();
    const [displayDate, setDisplayDate] = useState();
    const [totalOrderQuantity, setTotalOrderQuantity] = useState(0);
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [showLoadingOrderDetails, setShowLoadingOrderDetails] = useState(false);
    const [showOrderDetailsNotFound, setShowOrderDetailsNotFound] = useState(false);

    const getThisOrder = async (order_id) => {
        try{
            const getThisOrderReq = await axios.get(`${API_BASE_URL}order/kot/${order_id}`);
            if(getThisOrderReq.status === 200){
                setOrder(getThisOrderReq.data);
                let localDate = new Date(getThisOrderReq.data.ordered_at).toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
                let displayDate = moment(localDate).format('Do MMM, h:mm a');
                let totalQuantity = 0;
                getThisOrderReq.data.products.map(product => {
                    totalQuantity = totalQuantity + product.quantity
                });
                setTotalOrderQuantity(totalQuantity);
                setDisplayDate(displayDate);
                setShowLoadingOrderDetails(false);
                setShowOrderDetailsNotFound(false);
                setShowOrderDetails(true);
            }
            else{
                setShowOrderDetails(false);
                setShowLoadingOrderDetails(false);
                setShowOrderDetailsNotFound(true);
            }
        }
        catch (error) {
            setShowOrderDetails(false);
            setShowLoadingOrderDetails(false);
            setShowOrderDetailsNotFound(true);
        }
    }

    useEffect(() => {
        getThisOrder(id);
    }, []);

    return (
        <>
            {
                showOrderDetails
                ?
                <div className="invoiceContainer">
                    <div className="invoice">
                        <div className="invoiceInner">
                            <div className="invoiceBrandDetails">
                                <div className="invoiceBrandName">
                                    <p className="invoiceBrandNameTxt">blackbox pizza</p>
                                </div>
                                <div className="invoiceBrandAddress">
                                    <p className="invoiceBrandAddressTxt">563, 60f road, 6th main road, D-Group Layout, Sri ganda kaval, Nagarabhavi, Bengaluru, Karnataka 560091</p>
                                </div>
                                <div className="invoiceBrandPhoneNumber">
                                    <p className="invoiceBrandPhoneNumberTxt"><b>GSTIN:</b> 29EJTPP2617E1ZR</p>
                                </div>
                                <div className="invoiceBrandPhoneNumber">
                                    <p className="invoiceBrandPhoneNumberTxt"><b>FSSAI No.:</b> 11221332001251</p>
                                </div>
                                <div className="invoiceBrandGSTNumber">
                                    <p className="invoiceBrandPhoneNumberTxt"><b>Phone:</b> +91 80239 01652</p>
                                </div>
                                <div className="invoiceBrandGSTNumber">
                                    <p className="invoiceBrandPhoneNumberTxt"><b>Date:</b> {displayDate}</p>
                                </div>
                                <div className="invoiceBrandGSTNumber">
                                    <p className="invoiceBrandPhoneNumberTxt"><b>Customer Name:</b> {order.customer.name}</p>
                                </div>
                                <div className="invoiceBrandGSTNumber">
                                    <p className="invoiceBrandPhoneNumberTxt"><b>Customer Ph. No:</b> {order.customer.phone_number}</p>
                                </div>
                                {
                                    order.delivery_type == "DELIVERY"
                                    ?
                                    <div className="invoiceBrandGSTNumber">
                                        <p className="invoiceBrandPhoneNumberTxt"><b>Customer Address:</b> {order.delivery.address}</p>
                                    </div>
                                    :
                                    <></>
                                }
                                <div className="invoiceBrandGSTNumber">
                                    <p className="invoiceBrandPhoneNumberTxt"><b>Order ID:</b> BLCBOX{order.order_id}</p>
                                </div>
                            </div>

                            <div className="invoiceServiceType">
                                <p className="invoiceServiceTypeTxt">{order.delivery_type == "DINE_IN" ? "Dine In" : order.delivery_type == "TAKEAWAY" ? "Takeaway" : order.delivery_type == "DELIVERY" ? "Delivery" : <></>}</p>
                            </div>

                            <div className="invoicePaymentStatus">
                                <p className="invoicePaymentStatusTxt">{order.is_paid  ? "Paid" : "Unpaid"}</p>
                            </div>

                            <div className="invoiceOrderDetails">
                                <div className="invoiceOrderDetailsFields">
                                    <div className="invoiceOrderDetailsFieldStep item">
                                        <p className="invoiceOrderDetailsFieldStepTxt">item</p>
                                    </div>
                                    <div className="invoiceOrderDetailsFieldStep qty">
                                        <p className="invoiceOrderDetailsFieldStepTxt">qty</p>
                                    </div>
                                    <div className="invoiceOrderDetailsFieldStep price">
                                        <p className="invoiceOrderDetailsFieldStepTxt">price</p>
                                    </div>
                                    <div className="invoiceOrderDetailsFieldStep total">
                                        <p className="invoiceOrderDetailsFieldStepTxt">amt.</p>
                                    </div>
                                </div>
                                <div className="invoiceOrderDetailsItemsList">
                                    {
                                        order.products.map(product => {
                                            return(
                                                <div key={product.id} className="invoiceOrderDetailsItemSingle">
                                                    <div className="invoiceOrderDetailsItems">
                                                        <div className="invoiceOrderDetailsItemStep item">
                                                            <p className="invoiceOrderDetailsItemStepTxt">{product.product.name}</p>
                                                        </div>
                                                        <div className="invoiceOrderDetailsFieldStep qty">
                                                            <p className="invoiceOrderDetailsItemStepTxt">{product.quantity}</p>
                                                        </div>
                                                        <div className="invoiceOrderDetailsFieldStep price">
                                                            <p className="invoiceOrderDetailsItemStepTxt">{product.price}</p>
                                                        </div>
                                                        <div className="invoiceOrderDetailsFieldStep total">
                                                            <p className="invoiceOrderDetailsItemStepTxt">{product.price * product.quantity}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        product.variants.length != 0
                                                        ?
                                                        <>
                                                            {
                                                                product.variants.map(variant_type => {
                                                                    return(
                                                                        <>
                                                                            {
                                                                                variant_type.value.map(variant => {
                                                                                    return(
                                                                                        <>
                                                                                            {
                                                                                                variant.is_default == true && variant.is_selected == false
                                                                                                ?
                                                                                                <div className="invoiceOrderDetailsItemDetailsStep">
                                                                                                    <div className="invoiceOrderDetailsItemDetailsStepLable">
                                                                                                        <p className="invoiceOrderDetailsItemDetailsStepLableTxt">Removed: </p>
                                                                                                    </div>
                                                                                                    <div className="invoiceOrderDetailsItemDetailsStepValue">
                                                                                                        <p className="invoiceOrderDetailsItemDetailsStepValueTxt">{variant.name}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <></>
                                                                                            }

                                                                                            {
                                                                                                variant.is_default == false && variant.is_selected === true
                                                                                                ?
                                                                                                <div className="invoiceOrderDetailsItemDetailsStep">
                                                                                                    <div className="invoiceOrderDetailsItemDetailsStepLable">
                                                                                                        <p className="invoiceOrderDetailsItemDetailsStepLableTxt">Added: </p>
                                                                                                    </div>
                                                                                                    <div className="invoiceOrderDetailsItemDetailsStepValue">
                                                                                                        <p className="invoiceOrderDetailsItemDetailsStepValueTxt">{variant.name}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <></>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="invoiceOrderDetailsFields">
                                    <div className="invoiceOrderDetailsFieldStep item">
                                        <p className="invoiceOrderDetailsItemStepTxt">sub total</p>
                                    </div>
                                    <div className="invoiceOrderDetailsFieldStep qty">
                                        <p className="invoiceOrderDetailsItemStepTxt">{totalOrderQuantity}</p>
                                    </div>
                                    <div className="invoiceOrderDetailsFieldStep price">
                                        <p className="invoiceOrderDetailsItemStepTxt">&nbsp;</p>
                                    </div>
                                    <div className="invoiceOrderDetailsFieldStep total">
                                        <p className="invoiceOrderDetailsItemStepTxt">{order.costing.base_price}</p>
                                    </div>
                                </div>
                                <div className="invoiceOrderDetailsPriceBreakups">
                                    {
                                        order.costing.discount != 0
                                        ?
                                        <div className="invoiceOrderDetailsPriceBreakupStep">
                                            <div className="invoiceOrderDetailsPriceBreakupStepInner">
                                                <div className="invoiceOrderDetailsPriceBreakupStepLable">
                                                    <p className="invoiceOrderDetailsPriceBreakupStepLableTxt">Discount: </p>
                                                </div>
                                                <div className="invoiceOrderDetailsPriceBreakupStepValue">
                                                    <p className="invoiceOrderDetailsPriceBreakupStepValueTxt">{order.costing.discount}</p>
                                                </div>
                                            </div> 
                                        </div>
                                        :
                                        <></>
                                    }

                                    {
                                        order.costing.delivery_price != 0
                                        ?
                                        <div className="invoiceOrderDetailsPriceBreakupStep">
                                            <div className="invoiceOrderDetailsPriceBreakupStepInner">
                                                <div className="invoiceOrderDetailsPriceBreakupStepLable">
                                                    <p className="invoiceOrderDetailsPriceBreakupStepLableTxt">Delivery Price: </p>
                                                </div>
                                                <div className="invoiceOrderDetailsPriceBreakupStepValue">
                                                    <p className="invoiceOrderDetailsPriceBreakupStepValueTxt">{order.costing.delivery_price}</p>
                                                </div>
                                            </div> 
                                        </div>
                                        :
                                        <></>
                                    }
                                    {
                                        order.costing.delivery_discount != 0
                                        ?
                                        <div className="invoiceOrderDetailsPriceBreakupStep">
                                            <div className="invoiceOrderDetailsPriceBreakupStepInner">
                                                <div className="invoiceOrderDetailsPriceBreakupStepLable">
                                                    <p className="invoiceOrderDetailsPriceBreakupStepLableTxt">Delivery Discount: </p>
                                                </div>
                                                <div className="invoiceOrderDetailsPriceBreakupStepValue">
                                                    <p className="invoiceOrderDetailsPriceBreakupStepValueTxt">{order.costing.delivery_discount}</p>
                                                </div>
                                            </div> 
                                        </div>
                                        :
                                        <></>
                                    }
                                    {
                                        order.costing.discounted_delivery_price != 0
                                        ?
                                        <div className="invoiceOrderDetailsPriceBreakupStep">
                                            <div className="invoiceOrderDetailsPriceBreakupStepInner">
                                                <div className="invoiceOrderDetailsPriceBreakupStepLable">
                                                    <p className="invoiceOrderDetailsPriceBreakupStepLableTxt">Disc. Delivery Price: </p>
                                                </div>
                                                <div className="invoiceOrderDetailsPriceBreakupStepValue">
                                                    <p className="invoiceOrderDetailsPriceBreakupStepValueTxt">{order.costing.discounted_delivery_price}</p>
                                                </div>
                                            </div> 
                                        </div>
                                        :
                                        <></>
                                    }
                                    
                                    <div className="invoiceOrderDetailsPriceBreakupStep">
                                        <div className="invoiceOrderDetailsPriceBreakupStepInner">
                                            <div className="invoiceOrderDetailsPriceBreakupStepLable">
                                                <p className="invoiceOrderDetailsPriceBreakupStepLableTxt">CGST (2.5%): </p>
                                            </div>
                                            <div className="invoiceOrderDetailsPriceBreakupStepValue">
                                                <p className="invoiceOrderDetailsPriceBreakupStepValueTxt">{order.costing.tax / 2}</p>
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="invoiceOrderDetailsPriceBreakupStep">
                                        <div className="invoiceOrderDetailsPriceBreakupStepInner">
                                            <div className="invoiceOrderDetailsPriceBreakupStepLable">
                                                <p className="invoiceOrderDetailsPriceBreakupStepLableTxt">SGST (2.5%): </p>
                                            </div>
                                            <div className="invoiceOrderDetailsPriceBreakupStepValue">
                                                <p className="invoiceOrderDetailsPriceBreakupStepValueTxt">{order.costing.tax / 2}</p>
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <div className="invoiceOrderDetailsPriceTotal">
                                    <div className="invoiceOrderDetailsPriceTotalLable">
                                        <p className="invoiceOrderDetailsPriceTotalTxt">Total</p>
                                    </div>
                                    <div className="invoiceOrderDetailsPriceTotalValue">
                                        <p className="invoiceOrderDetailsPriceTotalTxt">{order.costing.final_price}</p>
                                    </div>
                                </div>

                                <div className="invoiceThanks">
                                    <p className="invoiceThanksTxt">thank you, please visit again.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
        </>
    )
}

export default KotPrint